export const slovaqueTranslation = {
  'Les lignes de commandes ont été mises à jour avec succès !':
    'Príkazové riadky boli úspešne aktualizované!',
  'Le texte a été enregistré avec succès': 'Text bol úspešne zaregistrovaný',
  'La traduction a été enregistrée avec succès': 'Preklad bol úspešne uložený',
  'Texte + Traduction enregistré avec succès':
    'Text + Preklad bol úspešne uložený',
  'Nombre de mots': 'Počet slov',
  Lingala: 'Lingala',
  'Prénom/Nom du client': 'Meno/priezvisko klienta',
  Bashkir: 'Baškirsko',
  "La date doit être ultérieure à aujourd'hui.":
    'Dátum musí byť neskorší ako dnes.',
  'Contenu rédigé': 'Napísaný obsah',
  'Nom/prénom': 'Priezvisko/meno',
  'Mot de passe incorrect': 'Nesprávne heslo',
  Institutions: 'Inštitúcie',
  'Veuillez donner un descriptif au document téléchargé':
    'Uveďte popis stiahnutého dokumentu',
  'Sichuan Yi ; Nuosu': 'Sichuan Yi; Nuosu',
  Suivant: 'Ďalšie',
  Kannada: 'Kannada',
  'Séparer les mots clés par un "enter"':
    'Kľúčové slová oddeľte klávesou "enter".',
  Allemand: 'Nemčina',
  Sango: 'Sango',
  'Min.': 'Min.',
  'un email contenant un lien de réinitialisation du mot de passe a été envoyé à votre email':
    'na váš e-mail bol odoslaný e-mail s odkazom na obnovenie hesla.',
  'Chef edito': 'Šéfredaktor',
  Croate: 'Chorvátsky',
  'Nb de textes': 'Počet textov',
  Violet: 'Violet',
  'Ce champs doit être sup ou égale nombre de texte':
    'Toto pole musí byť väčšie alebo rovné počtu textov',
  'a été activé avec succès': 'bol úspešne aktivovaný',
  Népalais: 'Nepál',
  'Nombre de FAQ': 'Počet často kladených otázok',
  Urdu: 'Urdu',
  'Analyses restantes': 'Zostávajúce analýzy',
  Indonésien: 'Indonézsky',
  Breton: 'Breton',
  Livrés: 'Doručené na',
  Associations: 'Asociácie',
  'Le client': 'Klient',
  'Révision demandée': 'Vyžiadaná recenzia',
  "Une erreur s'est produite, veuillez réessayer":
    'Vyskytla sa chyba, skúste to prosím znova',
  'Mise à jour non autorisée car des textes ont été livrés':
    'Neautorizovaná aktualizácia, keďže texty boli doručené',
  "Pour ajouter un texte (optimisation d'un contenu existant)":
    'Pridanie textu (optimalizácia existujúceho obsahu)',
  Gaélique: 'Gaelčina',
  'La ligne de commande pour': 'Príkazový riadok pre',
  'Business/Management': 'Podnikanie/manažment',
  Serbe: 'Srbský',
  "Merci d'indiquer ci-dessous vos demandes de modifications en prenant bien en compte nos conditions de repasses":
    'Nižšie uveďte svoje požiadavky na úpravy s ohľadom na naše podmienky žehlenia',
  'rédacteurs trouvés': 'nájdených redaktori ',
  'Kirghiz ; Kirghiz': 'Kirgiz; Kirgiz',
  Différence: 'Rozdiel',
  'en cours': 'prebieha',
  'Sami du Nord': 'Severní Laponci',
  Comparer: 'Porovnaj',
  Lituanien: 'Litovský',
  'Typix - Mon tableau de bord': 'Typix - Môj prístrojový panel',
  'Avoir au moins 8 caractères avec une lettre majuscule, un chiffre, un charactère spécial et ne pas être identique au nom':
    'Musí obsahovať aspoň 8 znakov, veľké písmeno, číslo, špeciálny znak a nesmie byť totožný s názvom.',
  Webmaster: 'Webmaster',
  'Veuillez saisir des champs valides': 'Zadajte platné polia',
  Basque: 'Baskicko',
  'Exporter .csv': 'Export .csv',
  'Confirmer le nouveau mot de passe': 'Potvrdenie nového hesla',
  "Pas d'options": 'Žiadne možnosti',
  'Banque/Assurance': 'Bankovníctvo/poistenie',
  'Vos demandes': 'Vaše požiadavky',
  'Coût d\u2019achat': 'Nákupné náklady',
  Tamil: 'Tamil',
  'Le texte a été supprimé avec succès': 'Text bol úspešne odstránený',
  'Mes Commandes': 'Moje objednávky',
  Zoulou: 'Zulu',
  Afficher: 'Zobraziť',
  Texte: 'Text',
  Islandais: 'Islandský',
  Russe: 'Ruský',
  'Tous les textes': 'Všetky texty',
  Czech: 'Český',
  'Comparaison hors ligne': 'Porovnanie offline',
  Téléphonie: 'Telefonovanie',
  'Valider le texte': 'Overenie textu',
  'Votre text ne de doit pas dépasser 500 mots':
    'Váš text by nemal presiahnuť 500 slov',
  'Coquilles (double espace, « s »\u2026)':
    'Škrupiny (dvojitá medzera, "s"...)',
  'le consultant': 'konzultant',
  Coréen: 'Kórejský',
  'Nb de Silo': 'Počet síl',
  Validés: 'Overené',
  'non attribuées': 'nepridelené',
  Néerlandais: 'Holandský',
  'Voulez-vous vraiment supprimer': 'Naozaj chcete vymazať',
  Rundi: 'Rundi',
  Regional: 'Regionálne',
  'Compte client': 'Účet zákazníka',
  Global: 'Globálne',
  'Tous les rôles': 'Všetky úlohy',
  Musique: 'Hudba',
  Perso: 'Osobné',
  Kinyarwanda: 'Kinyarwanda',
  'Il semblerait que tu n\u2019aies plus de crédit d\u2019analyse':
    'Zdá sa, že už nemáte žiadny kredit na analýzu',
  Bambara: 'Bambara',
  Sortir: 'Vystúpte',
  optionel: 'voliteľné',
  'Temps restant': 'Zostávajúci čas',
  Ukrainien: 'Ukrajinský',
  'Chichewa ; Chewa ; Nyanja': 'Chichewa; Chewa; Nyanja',
  'Votre demande de révision a bien été envoyée.':
    'Vaša žiadosť o preskúmanie bola odoslaná.',
  'Typix - Créer rédacteur': 'Typix - Vytvorenie redaktori ',
  "n'existe pas": 'neexistuje',
  Hindi: 'Hindčina',
  'ne peut pas être supprimé car il est lié à des sites web':
    'nemožno vymazať, pretože je prepojená s webovými stránkami.',
  'Commandes en cours': 'Prebiehajúce objednávky',
  'Textes livrés': 'Doručené texty',
  Validation: 'Overovanie',
  'Non attribuées': 'Nepridelené',
  'Voulez-vous vraiment désactiver': 'Naozaj chcete deaktivovať',
  'Catalan ; Valencien': 'katalánčina; valencijčina',
  'Nombre de textes restants': 'Počet zostávajúcich textov',
  'Ne doit pas contenir votre nom': 'Nesmie obsahovať vaše meno',
  Documentation: 'Dokumentácia',
  Kazakh: 'Kazachstan',
  'Numéro invalide': 'Neplatné číslo',
  'Veuillez saisir un texte ou importer un fichier.':
    'Zadajte text alebo importujte súbor.',
  'Nb de FAQ': 'Počet často kladených otázok',
  Analyser: 'Analýza',
  Turc: 'Turecký',
  'Toute demande n\u2019ayant pas été précisée lors du brief':
    'Všetky požiadavky, ktoré nie sú uvedené v stručnom dokumente',
  Wallon: 'Valónsky',
  Birmane: 'Barmský',
  Irlandais: 'Írsky',
  Oriya: 'Oriya',
  'Réinitialiservotre mot de passe': 'Obnovenie hesla',
  Cuisine: 'Kuchyňa',
  'Haïtien ; créole haïtien': 'haitčina; haitská kreolčina',
  'Vous ne pouvez pas supprimer le rédacteur': 'redaktori  nemôžete vymazať',
  Validé: 'Overené',
  "cet email n'existe pas": 'tento e-mail neexistuje',
  Historique: 'História',
  Confirmer: 'Potvrďte',
  'à cocher si le nombre de mots est différent par texte':
    'zaškrtnúť, ak sa počet slov v texte líši.',
  'Fiche du site web ': 'Hárok webového sídla',
  Désactiver: 'Deaktivácia stránky',
  'Travaux/BTP': 'Práce/stavebníctvo',
  'La fiche du website': 'Hárok webovej stránky',
  'Si le contenu ne respecte pas les règles du brief':
    'Ak obsah nerešpektuje pravidlá stručného opisu',
  Norvégien: 'Nórsky',
  '0 rédacteurs': '0 redaktori ',
  indisponible: 'nedostupné',
  Venda: 'Venda',
  Maori: 'Maori',
  'Textes revisés': 'Revidované texty',
  'Status texte': 'Stav textu',
  'textes livrés en attente de validation':
    'doručené texty čakajúce na overenie',
  'Typix - Mon Profile': 'Typix - Môj profil',
  'le client': 'klient',
  Romanche: 'Romanche',
  'URL du site': 'Adresa URL stránky',
  'nouvelles notifications': 'nové oznámenia',
  'Divehi ; Dhivehi ; Maldivien': 'Divehi; Dhivehi; maldivský',
  'Êtes vous sur de vouloir sauvegarder le texte':
    'Ste si istí, že chcete text uložiť',
  'Veuillez ajouter un texte initiale afin de pouvoir sauvegarder le texte en brouillon':
    'Pridajte počiatočný text, aby ste text uložili ako návrh',
  Xhosa: 'Xhosa',
  'Le contrat pour le client': 'Zmluva pre zákazníka',
  rédacteurs: 'redaktori ',
  'Ce champ est obligatoire': 'Toto pole je povinné',
  'Tarif/Mot': 'Sadzba/slovo',
  Tadjik: 'Tadžik',
  'Réf devis': 'Ref citát',
  'Rechercher un rédacteur': 'Vyhľadávanie redaktori ',
  Albanais: 'Albánčina',
  Galicien: 'Galícia',
  Disponibilité: 'Dostupnosť',
  'Les deux mots de passe ne sonts pas identiques':
    'Tieto dve heslá nie sú totožné',
  Tibétain: 'Tibeťan',
  'Typix - Gestion des utilisateurs': 'Typix - Správa používateľov',
  'Ossétien ; Ossétique': 'osetský; osetský',
  Tswana: 'Tswana',
  'Ajouter un client': 'Pridanie zákazníka',
  'Séparez les mots-clés par un " Enter ".':
    'Kľúčové slová oddeľte klávesou "Enter".',
  'Zhuang ; Chuang': 'Zhuang; Chuang',
  Thématiques: 'Témy',
  'Nb mots/texte': 'Počet slov/textov',
  Commerce: 'Obchod',
  'Voir les commandes': 'Pozrite si objednávky',
  'Nombre de mots par texte': 'Počet slov v texte',
  'Lien drive...': 'Odkaz na pohon...',
  'Hollandais ; Flamand': 'holandčina; flámčina',
  'Mon profil': 'Môj profil',
  "Ce champs doit être sup à 0 car facturation n'est pas au réel":
    'Toto pole musí byť väčšie ako 0, pretože fakturácia nie je skutočná',
  'Le texte a été validé avec succès': 'Text bol úspešne overený',
  Jeux: 'Hry',
  'Voulez-vous vraiment supprimer la ligne de commande pour':
    'Naozaj chcete odstrániť príkazový riadok pre',
  'Ndebele, Nord ; Ndebele Nord': 'Ndebele, Sever; Ndebele North',
  'Slave ecclésiastique ; vieux slavon ; slavon ecclésiastique ; vieux bulgare ; vieux slavon ecclésiastique':
    'cirkevná slovančina; staroslovienčina; cirkevná slovančina; stará bulharčina; stará cirkevná slovančina',
  Swahili: 'Svahilčina',
  Voyage: 'Cestovanie',
  'Nombre de mot à ajouter': 'Počet slov, ktoré sa majú pridať',
  'Liste des rédacteurs': 'Zoznam redaktori ',
  'le rédacteur': 'redaktor',
  Kanuri: 'Kanuri',
  rédacteur: 'redaktor',
  Tatar: 'Tatar',
  'Roumain ; Moldave ; Moldovan': 'rumunčina; moldavčina',
  'Le texte initial de': 'Pôvodný text',
  'Mail clients': 'Zákaznícka pošta',
  'Le contrat de': 'Zmluva o',
  'au moins une lettre minuscule et une lettre majuscule,un caractère spécial et un chiffre':
    'aspoň jedno malé a jedno veľké písmeno, jeden špeciálny znak a jedna číslica',
  Transport: 'Doprava',
  'Nombre total de mots (existants et ajoutés) :':
    'Celkový počet slov (existujúcich a pridaných) :',
  'Rechercher par mail, prénom, nom, site...':
    'Vyhľadávanie podľa e-mailu, mena, priezviska, stránky...',
  'Nombre de mot à ajouter (Min/Max) : ':
    'Počet slov, ktoré sa majú pridať (Min/Max) :',
  Compte: 'Účet',
  'Tourisme/Loisirs': 'Cestovný ruch/voľný čas',
  'Nouveau texte': 'Nový text',
  Max: 'Max',
  'Kalaallisut ; Groenlandais': 'Kalaallisut; grónsky',
  'Oups...!il y a une/plusieurs erreur(s) dans la fenêtre du gap sémantique. Veuillez vérifier son contenu pour la/les corriger et soumettre à nouveau votre texte.':
    'Ups...! V okne sémantickej medzery je jedna alebo viacero chýb. Skontrolujte jeho obsah, aby ste ho/ich opravili, a znovu odošlite svoj text.',
  'ne peut pas être supprimé pour le moment':
    'v súčasnosti nie je možné vymazať.',
  'Le texte': 'Text',
  URL: 'ADRESA URL',
  "Un utilisateur existe déjà avec l'email":
    'Už existuje používateľ s e-mailom',
  Cree: 'Cree',
  Prénom: 'Krstné meno',
  'commandes prête': 'pripravené objednávky',
  Réinitialiser: 'Obnovenie',
  'Veuillez fournir une date valide': 'Uveďte platný dátum',
  'Kuanyama ; Kwanyama': 'Kuanyama; Kwanyama',
  'Luxembourgeois ; Letzeburgesch': 'Luxemburčina; Letzeburgesch',
  Suédois: 'Švédsky',
  'Confirmer le mot de passe': 'Potvrdenie hesla',
  'Ce champs est obligatoire et ne peut avoir que des nombres':
    'Toto pole je povinné a môže obsahovať len čísla',
  Sciences: 'Veda',
  Tchétchène: 'Čečensko',
  "est le seul client pour un site, il faut ajouter d'autres pour pouvoir le supprimer":
    'je jediným klientom pre lokalitu, na jej odstránenie je potrebné pridať ďalšie',
  'Le mot de passe de confirmation ne correspond pas':
    'Potvrdzovacie heslo sa nezhoduje',
  Swati: 'Swati',
  Danois: 'Dánsky',
  Sauvegarder: 'Uložiť',
  'Veuillez changer les informations suivantes titre et/ou mot clé':
    'Zmeňte tieto informácie: názov a/alebo kľúčové slovo',
  'importer un document': 'importovať dokument',
  Maltais: 'Maltský',
  'Nb de mots': 'Počet slov',
  'Les données fournies sont invalides': 'Poskytnuté údaje sú neplatné',
  'Livraison estimée': 'Odhadované dodanie',
  'Grec moderne (1453-)': 'Moderná gréčtina (1453-)',
  Avestan: 'Avestský',
  Inuktitut: 'Inuktitut',
  'Nombre total de mots ajoutés': 'Celkový počet pridaných slov',
  'le contenu présente des inexactitudes': 'obsah obsahuje nepresnosti',
  Finnois: 'Fínsky',
  'Ajouter un admin': 'Pridanie správcu',
  'Min/Max': 'Min/Max',
  'Modifier le consultant': 'Úprava konzultanta',
  'Liste des clients': 'Zoznam klientov',
  'Nom client': 'Názov zákazníka',
  Vert: 'Zelená',
  'Mots clés manquants': 'Chýbajúce kľúčové slová',
  Lao: 'Lao',
  Somali: 'Somálsko',
  'Nb de silo par texte': 'Počet síl na text',
  Italien: 'Taliansky',
  'La commande a été mis à jour avec succès !':
    'Objednávka bola úspešne aktualizovaná!',
  Bosniaque: 'Bosnianska',
  'Thématiques d\u2019expertise': 'Témy odborných znalostí',
  'Ce mot de passe est le mot de passe que vous avez oublié':
    'Toto je heslo, ktoré ste zabudli',
  'a été désactivé avec succès': 'bol úspešne deaktivovaný',
  Kongo: 'Kongo',
  'Postuler à la commande': 'Požiadať o objednávku',
  'mot-clé': 'kľúčové slovo',
  Abkhazien: 'Abcházsko',
  Mongolien: 'Mongolský',
  'Typix - Fiche site web': 'Typix - webová stránka',
  'Mail envoyé': 'Odoslaná pošta',
  Géorgien: 'Gruzínsky',
  'Si des phrases ne sont pas dans un français correct':
    'Ak vety nie sú v správnej francúzštine',
  'En cours': 'Prebieha',
  voir: 'pozri',
  "Date d'ajout": 'Dátum pridania',
  'Tarif au mot': 'Sadzba za slovo',
  Thai: 'Thai',
  'Détails du texte': 'Podrobnosti o texte',
  'Commandes en retard': 'Oneskorené objednávky',
  disponible: 'k dispozícii na',
  Droit: 'Vpravo',
  Azerbaïdjanais: 'Azerbajdžan',
  'Ajouter un consultant': 'Pridať konzultanta',
  'Dans le cadre d\u2019une repasse sur des contenus, seuls les éléments suivants seront pris en charge':
    'Pri prehrávaní obsahu budú podporované len tieto položky',
  'doit comprendre au moins 8 charactères': 'musí obsahovať aspoň 8 znakov',
  'Titre de la commande': 'Názov objednávky',
  'Langues bihari': 'Biharské jazyky',
  'Livraison estimé au': 'Odhadované dodanie do',
  "Le nombre de texte de la commande ne peut pas être inférieur aux nombre de textes attribuées au(x) rédacteur(s). Veuillez d'abord modifier les informations de la ligne de commande":
    'Počet textov v objednávke nesmie byť menší ako počet textov pridelených autorovi (autorom). Najskôr zmeňte informácie v príkazovom riadku',
  'Date de livraison': 'Dátum dodania',
  'Voulez-vous vraiment supprimer le contrat de': 'Naozaj chcete zrušiť',
  'Facturation au réel': 'Skutočné vyúčtovanie',
  'Gap sémantique': 'Sémantická medzera',
  Estonien: 'Estónčina',
  Wolof: 'Wolof',
  'Coût d\u2019achat max/mot': 'Maximálne nákupné náklady na slovo',
  'Détail de la commande': 'Podrobnosti o objednávke',
  'Export en cours de téléchargement\u2026': 'Nahrávanie exportu...',
  'Ce champ est obligatoire et doit être inférieur ou égal au Nombre total des textesde la commande':
    'Toto pole je povinné a musí byť menšie alebo rovné celkovému počtu textov v objednávke',
  Arabe: 'Arabčina',
  Bislama: 'Bislama',
  'Mail client': 'Zákaznícka pošta',
  '(Titre, mot clé, url, balise, méta-description)':
    '(Názov, kľúčové slová, url, tag, meta popis)',
  'Typix - Nouvelle commande': 'Typix - Nová objednávka',
  'Révision en attente': 'Preskúmanie prebieha',
  'Le consultant': 'Konzultant',
  Entreprise: 'Spoločnosť',
  Aragonais: 'Aragónsky',
  Kashmiri: 'Kašmír',
  'a été activé': 'bola aktivovaná',
  'Interlingua (Association internationale des langues auxiliaires)':
    'Interlingua (Medzinárodná asociácia pomocných jazykov)',
  Actualités: 'Novinky',
  'Votre décision a été prise en compte avec succès':
    'Vaše rozhodnutie bolo úspešne zohľadnené',
  Afrikaans: 'Afrikaans',
  'contenu en attente de vérification client':
    'obsah čaká na overenie zákazníkom',
  'Fiche du rédacteur': 'Súbor redaktora',
  Outillage: 'Nástroje',
  'Typix - Détails de la commande': 'Typix - Podrobnosti o objednávke',
  'Ajouter ou compléter ci-dessous votre contenu :':
    'Nižšie pridajte alebo doplňte svoj obsah:',
  Ewe: 'Ovce',
  'contenu en attente de repasse rédacteur':
    'redaktori  prehrávania obsahu v očakávaní',
  'La commande a été supprimé avec succès': 'Objednávka bola úspešne vymazaná',
  'Expressions ou phrases françaises mais ne vous convenant pas':
    'Francúzske výrazy alebo frázy, ktoré vám nesedia',
  Corse: 'Korzika',
  Modifier: 'Upraviť',
  'Nb de GMB': 'Počet GMB',
  Oromo: 'Oromo',
  Polish: 'Poľský',
  'Insérez votre gap sémantique avec l’occurrence':
    'Vložte sémantickú medzeru s výskytom',
  'Nb de textes à optimiser': 'Počet textov, ktoré sa majú optimalizovať',
  Mode: 'Režim',
  Akan: 'Akan',
  'Révisions effectués': 'Vykonané revízie',
  'Modifier l\u2019admin': 'Upraviť správcu',
  'Télécharger le document': 'Stiahnite si dokument',
  Hausa: 'Hausa',
  'Notes rédacteurs': 'Poznámky redakcie',
  Quechua: 'Quechua',
  Féroïen: 'Faerské ostrovy',
  'Modifier le rédacteur': 'Zmena redaktori ',
  Mail: 'Mail',
  Rédacteurs: 'redaktori ',
  'Typix - Détails du texte': 'Typix - Podrobnosti o texte',
  Hongrois: 'Maďarský',
  'Bon pour livraison': 'Dobré na doručenie',
  'Au réel': 'V reálnom svete',
  'Hiri Motu': 'Hiri Motu',
  Hébreu: 'Hebrejčina',
  'Nombre total de mots ajoutés :': 'Celkový počet pridaných slov :',
  Komi: 'Komi',
  'Doit être inférieur au nombre maximum': 'Musí byť menší ako maximálny počet',
  'Nom du texte': 'Názov textu',
  'Ajouter un rédacteur': 'Pridanie redaktori ',
  Ou: 'Alebo',
  Utilisateurs: 'Používatelia',
  Heures: 'Hodiny',
  'Santé/Bien-être': 'Zdravie/pohoda',
  'Interlingue ; Occidental': 'Medzijazykové; západné',
  'Luba-Katanga': 'Luba-Katanga',
  Letton: 'Lotyšský',
  Anglais: 'Angličtina',
  Envoyer: 'Odoslať',
  'Tableau de bord': 'Prístrojová doska',
  'Demande de révision': 'Žiadosť o preskúmanie',
  'Résultat sémantique': 'Sémantický výsledok',
  'Chercher un rédacteur': 'Vyhľadávanie redaktora',
  'Ce champs est obligatoire': 'Toto pole je povinné',
  Rédaction: 'Redakcia',
  'Modifier le client': 'Úprava klienta',
  'Voulez-vous vraiment supprimer le rédacteur':
    'Naozaj chcete odstrániť redaktori ',
  Titre: 'Názov',
  Langue: 'Jazyk',
  'Mot clé': 'Kľúčové slová',
  'Les demandes suivantes ne seront pas prises en compte':
    'Tieto žiadosti sa nebudú posudzovať',
  'délai dépassé': 'zmeškaný termín',
  Tchouvache: 'Tchouvache',
  Ganda: 'Ganda',
  Twi: 'Twi',
  'Contrat déjà existant, veuillez modifier les informations':
    'Existujúca zmluva, upravte prosím informácie',
  Igbo: 'Igbo',
  Yiddish: 'Jidiš',
  'Limburgan ; Limburger ; Limburgish': 'Limburgan; Limburger; Limburgish',
  'Commandes validées': 'Overené objednávky',
  'listeRedacteur-': 'listRedactor-',
  Étape: 'Krok',
  'Energie/Environnement': 'Energia/životné prostredie',
  Min: 'Min',
  Gujarati: 'Gudžarátčina',
  'ne doit pas inclure votre nom/prénom':
    'nesmie obsahovať vaše meno/krstné meno',
  'Typix - Fiche rédacteur': 'Typix - Spisovateľský súbor',
  'révisions effectuées en attentes de validation':
    'revízie, ktoré čakajú na validáciu',
  'Textes validés': 'Overené texty',
  Art: 'Art',
  Dzongkha: 'Dzongkha',
  Polyvalent: 'Všestranný',
  Sundanese: 'Sundanese',
  'Nb total de textes': 'Celkový počet textov',
  'Nombre de mots gérés': 'Počet spravovaných slov',
  "L'émail": 'Smalt',
  'Afficher les résultats': 'Zobraziť výsledky',
  'Frison occidental': 'západofrízština',
  'Modification effectuée avec succès': 'Úprava bola úspešne dokončená',
  'Balise titre': 'Značka Title',
  'Typix - Mon profil': 'Typix - Môj profil',
  'email invalide': 'neplatný e-mail',
  'Veuillez ajouter une url afin de pouvoir sauvegarder le texte en brouillon':
    'Pridajte url adresu, aby ste mohli text uložiť ako návrh',
  Macédonien: 'Macedónčina',
  'Votre candidature a bien été envoyée': 'Vaša žiadosť bola odoslaná',
  'E-mail': 'E-mail',
  Fulah: 'Fulah',
  'Ajouter ou compléter ci-dessous votre contenu':
    'Pridajte alebo doplňte svoj obsah nižšie',
  Manx: 'Manx',
  'Ajout de mots-clés avec la fréquence restante':
    'Pridanie kľúčových slov so zostávajúcou frekvenciou',
  'Fiche du site web': 'Hárok webového sídla',
  Contacter: 'Kontakt',
  Fidjien: 'Fidži',
  Déconnexion: 'Odpojenie',
  email: 'e-mail',
  'Nombre de textes': 'Počet textov',
  'Enregistrer et informer client': 'Registrácia a informovanie zákazníkov',
  'Date de validation': 'Dátum overenia',
  'Tonga (Îles Tonga)': 'Tonga (Tongské ostrovy)',
  Javanais: 'Javánčina',
  Portugais: 'Portugalčina',
  Biélorusse: 'Bieloruský',
  'Pour ajouter un nouveau texte': 'Pridanie nového textu',
  'Enfant/Puéri': 'Dieťa/Pupium',
  'Mots clés': 'Kľúčové slová',
  Emploi: 'Zamestnanie',
  'Voulez-vous vraiment supprimer la commande de': 'Naozaj chcete odstrániť',
  'Mot clé': 'Kľúčové slovo',
  Consultants: 'Poradcovia',
  Volapük: 'Volapük',
  Services: 'Služby',
  'mot-clé (fréquence)': 'kľúčové slovo (frekvencia)',
  'Ne plus afficher ce message': 'Túto správu už nezobrazujte',
  Consultant: 'Konzultant',
  "Une erreur s'est produite": 'Vyskytla sa chyba',
  'Avoir au moins 8 caractères avec une lettre majuscule, un chiffre, un charactère spécial':
    'Najmenej 8 znakov s veľkým písmenom, číslicou, špeciálnym znakom',
  'Pushto ; Pashto': 'Pušto; paštúnčina',
  Français: 'Francúzsky',
  validées: 'overené',
  'Url du site': 'Url stránky',
  Précédent: 'Predchádzajúci',
  'car celui-ci a déjà rédigé des textes': 'pretože tá už vypracovala texty',
  'Kikuyu ; Gikuyu': 'Kikuyu; Gikuyu',
  Brouillons: 'Návrhy',
  Ido: 'Ido',
  'Contenu disponible': 'Dostupný obsah',
  Enseignement: 'Vyučovanie',
  'a été livré avec succès': 'bola úspešne doručená',
  Chinois: 'Čínsky',
  Vietnamien: 'Vietnamci',
  'Nouveau client': 'Nový zákazník',
  'Votre profil a été mis à jour avec succès.':
    'Váš profil bol úspešne aktualizovaný.',
  Consignes: 'Pokyny',
  'Mot de passe': 'Heslo',
  Annuler: 'Zrušiť',
  Non: 'Nie',
  Nom: 'Názov',
  Guarani: 'Guarani',
  "il y'a des commandes liés à au site web":
    'existujú objednávky týkajúce sa webovej stránky',
  Score: 'Skóre',
  'Mot de passe mis à jour avec succès': 'Heslo bolo úspešne aktualizované',
  Latin: 'Latinčina',
  Technologie: 'Technológia',
  Internet: 'Internet',
  Nauru: 'Nauru',
  'a été créé avec succès': 'bol úspešne vytvorený',
  Roumain: 'Rumunský',
  Assamais: 'Assamese',
  'Khmer central': 'Stredný Khmer',
  'Navajo ; Navaho': 'Navajo; Navaho',
  'Panjabi ; Punjabi': 'Pandžábčina; pandžábčina',
  'Analyse/Limite': 'Analýza/limit',
  Grec: 'Grécky',
  Ojibwa: 'Ojibwa',
  'Mes Projets': 'Moje projekty',
  'keyword1 (2)\nkeyword2 (1)\nkeyword3 (1)\nkeyword4 (2)\n\u2026':
    'kľúčové slovo1 (2)\nkľúčové slovo2 (1)\nkľúčové slovo3 (1)\nkľúčové slovo4 (2)\n...',
  'ajouter un texte': 'pridať text',
  'Détail de la prestation': 'Podrobnosti o službe',
  "Vous avez la possibilité d'exporter le rapport d'une, ou de plusieurs commandes. Pour exporter toutes les commandes d'un client, il vous suffit juste de cliquer sur l'icône de téléchargement en jaune":
    'Máte možnosť exportovať správu jednej alebo viacerých objednávok. Ak chcete exportovať všetky objednávky zákazníka, stačí kliknúť na žltú ikonu na stiahnutie',
  'Gestion des utilisateurs': 'Správa používateľov',
  'livré et inférieur au nombre de texte non affecté':
    'a menej ako počet nepriradených textov',
  'Date deadline validation client': 'Termín overenia zákazníka',
  Aymara: 'Aymara',
  Validées: 'Overené',
  Notes: 'Poznámky',
  'Nombre de GMB': 'Počet GMB',
  'résultats sur': 'výsledky na',
  'Prénom / Nom du client': 'Krstné meno / Meno klienta',
  'Tout télécharger': 'Stiahnite si všetky',
  'Champs obligatoires': 'Povinné polia',
  Éditer: 'Upraviť',
  'Coller le texte initial dans le champs ci-dessous':
    'Vložte pôvodný text do poľa nižšie',
  'ID rédacteur': 'ID redaktori ',
  Arménien: 'Arménsky',
  'Grande distribution': 'Veľkoplošná distribúcia',
  Welsh: 'Welsh',
  Herero: 'Herero',
  'Enrichissement sémantique': 'Sémantické obohatenie',
  'Détail de la prestation du contenu': 'Podrobnosti o doručovaní obsahu',
  Noir: 'Čierna',
  'Ajouter un texte': 'Pridanie textu',
  Traduction: 'Preklad',
  'Immo/Defisc': 'Majetok/daň',
  'Brief détaillé': 'Podrobný prehľad',
  'Séparez les mots-clés par un Enter .':
    'Kľúčové slová oddeľte klávesom Enter .',
  Prix: 'Ocenenia',
  'mot de passe oublié': 'Zabudnuté heslo',
  'Ndebele, South ; South Ndebele': 'Ndebele, južný; južný Ndebele',
  'Demander une révision': 'Vyžiadajte si recenziu',
  'Demander une repasse globale': 'Požiadajte o celkovú revíziu',
  'Sotho, Southern': 'Sotho, južný',
  'Le titre de la commande existe déjà': 'Názov objednávky už existuje',
  'Félicitations !': 'Gratulujeme!',
  Kurde: 'Kurdská',
  Adresse: 'Adresa',
  Espagnol: 'Španielčina',
  Ndonga: 'Ndonga',
  'En retard': 'Neskoro',
  'a été ajouté avec succès': 'bol úspešne pridaný',
  Commandes: 'Objednávky ',
  'a été mis en brouillon': 'bola vypracovaná',
  'Rédacteur attribué': 'Pridelený redaktori ',
  Rechercher: 'Vyhľadávanie',
  'Nouvelle commande': 'Nová objednávka',
  'Commandes non attribuées': 'Nepridelené objednávky',
  'Le texte a été mis en brouillon': 'Text bol vypracovaný',
  'Contact edito': 'Kontaktovať edito',
  Langues: 'Jazyky',
  Orange: 'Oranžová',
  'Nynorsk norvégien': 'Nórsky nynorsk',
  Shona: 'Shona',
  Turkmène: 'Turkméni',
  'contenu repassé, en attente validation client':
    'vyžehlený obsah, ktorý čaká na overenie zákazníkom',
  'Nombre de mots rédigés': 'Počet napísaných slov',
  Malay: 'Malajčina',
  'Nombre de Silo': 'Počet síl',
  Oui: 'Áno',
  Automobile: 'Automobilový priemysel',
  Résultats: 'Výsledky',
  Afar: 'Afar',
  'Tâche - id tâche': 'Úloha - id úlohy',
  'Ce champ est obligatoire doit être supérieur à 0':
    'Toto pole je povinné a musí byť väčšie ako 0',
  'Vous êtes sûr': 'Ste si istý, že',
  'Réinitialiser votre mot de passe': 'Obnovenie hesla',
  Sindhi: 'Sindhi',
  Bengali: 'Bengálčina',
  'Bokmål, norvégien ; Bokmål norvégien': 'Bokmål, nórčina; Norwegian Bokmål',
  'Nombre de Jours': 'Počet dní',
  'Min/Max mots': 'Min/Max slová',
  Slovène: 'Slovinčina',
  Site: 'Webová lokalita',
  'Sinhala ; Cingalais': 'Sinhálčina; sinhálčina',
  Bulgare: 'Bulharský',
  'Liste des commandes': 'Zoznam objednávok',
  "L'utilisateur": 'Používateľ',
  Uzbek: 'Uzbek',
  'Titre du texte': 'Názov textu',
  Postuler: 'Prihlášku podajte na',
  'Nb de textes par Silo': 'Počet textov na silo',
  'Nombre total de mots (existants et ajoutés)':
    'Celkový počet slov (existujúcich a pridaných)',
  'Max.': 'Max.',
  Beauté: 'Krása',
  Humanitaire: 'Humanitárne',
  Telugu: 'Telugu',
  'Recherche par rédacteur ou texte': 'Hľadať podľa autora alebo textu',
  'Balise meta-description': 'Meta popisná značka',
  Malgache: 'Malgašsko',
  Tagalog: 'Tagalog',
  'Ce champs doit être sup à 0 et inférieur à 1':
    'Toto pole musí byť väčšie ako 0 a menšie ako 1',
  'Le rédacteur': 'Redaktor',
  'a été mis à jour avec succès': 'bol úspešne aktualizovaný',
  'Avoir au moins 8 caractères avec une lettre majuscule, un chiffre, un symbole et ne pas être identique au nom.':
    'Musí obsahovať aspoň 8 znakov, veľké písmeno, číslo, symbol a nesmie byť totožný s názvom.',
  'textes en plus': 'ďalšie texty',
  'Format souhaité': 'Požadovaný formát',
  Chamorro: 'Šamorínsky',
  Malayalam: 'Malayalam',
  'Détail ligne de commande': 'Detail riadku objednávky',
  'Veuillez préciser la date': 'Uveďte dátum',
  Pali: 'Pali',
  Clients: 'Zákazníci',
  'Total facturé': 'Celková fakturovaná suma',
  'contenu validé par le client': 'obsah potvrdený klientom',
  'Occitan (post 1500)': 'okcitánčina (po roku 1500)',
  Japonais: 'Japonský',
  Avaric: 'Avaric',
  'Voulez-vous vraiment activer': 'Naozaj chcete aktivovať',
  Sanskrit: 'Sanskrit',
  'Toutes les notifications': 'Všetky oznámenia',
  Marathi: 'Marathi',
  Persan: 'Perzský',
  'La commande pour le client': 'Objednávka pre zákazníka',
  'Intitulé de la commande': 'Názov objednávky',
  Samoan: 'Samoan',
  Autres: 'Iné',
  Sport: 'Šport',
  "il y'a des commandes liés au site web ":
    'existujú objednávky týkajúce sa webovej stránky',
  'Ajout de mots-clé avec la fréquence restante':
    'Pridanie kľúčových slov so zostávajúcou frekvenciou',
  'a été supprimé avec succès': 'bol úspešne odstránený',
  Postulé: 'Publikované',
  Literie: 'Posteľná bielizeň',
  'Édité par le client': 'Upravené klientom',
  Cornouailles: 'Cornwall',
  Tigrinya: 'Tigrinya',
  Textes: 'Texty',
  'Soumettre un nouveau texte': 'Odoslanie nového textu',
  Téléphone: 'Telefón',
  Deadline: 'Termín',
  "Une erreur s'est produite. Veuillez vérifier votre saisie ou essayer plus tard.":
    'Vyskytla sa chyba. Skontrolujte svoj záznam alebo to skúste neskôr.',
  Marshallais: 'Marshall',
  "La demande d'ajout de crédit est envoyée":
    'Požiadavka na pridanie kreditu sa odošle',
  'Le rédacteur ': 'Redaktor',
  'Demander des crédits': 'Žiadosť o úver',
  "Cette commande n'existe pas": 'Tento príkaz neexistuje',
  Inupiaq: 'Inupiaq',
  Tahitien: 'Tahitský',
  Admins: 'Administrátori',
  'Mettez des mots-clés avec fréquence':
    'Nastavenie kľúčových slov s frekvenciou',
  Notifications: 'Oznámenia',
  'en retard': 'neskoro',
  "l'admin": 'administrátor',
  'Mail rédacteurs': 'Redaktori pošty',
  Amharique: 'Amharčina',
  Societe: 'Spoločnosť',
  Tsonga: 'Tsonga',
  'Nombre de textes ajoutés': 'Počet pridaných textov',
  Société: 'Spoločnosť',
  Yoruba: 'Yoruba',
  'Le texte a été livré avec succès': 'Text bol úspešne doručený',
  'Mettez votre texte': 'Vložte svoj text',
  "Oups....! Une erreur s'est produite": 'Oops....! Vyskytla sa chyba',
  'Prénom/Nom': 'Meno/priezvisko',
  'Max doit être sup à Min': 'Max musí byť väčšia ako Min',
  'Importer un autre document': 'Importovať iný dokument',
  'Votre demande a expirée .. veuillez refaire une autre':
    'Platnosť vašej žiadosti vypršala... vytvorte si novú',
  'Veuillez corriger le format de votre gap sémantique afin de pouvoir sauvegarder le texte en brouillon':
    'Prosím, opravte formát vašej sémantickej medzery, aby ste mohli text uložiť ako návrh',
  Exemple: 'Príklad',
  Slovaque: 'Slovenská',
  Finance: 'Financie',
  Réactiver: 'Znovu aktivujte stránku',
  'Nb textes': 'Počet textov',
  'Intitulé commande': 'Názov objednávky',
  Supprimer: 'Odstrániť',
  Brouillon: 'Návrh',
  'Mots clés restants': 'Zostávajúce kľúčové slová',
  Date: 'Dátum',
  Rôle: 'Úloha',
  'Ce champs doit être sup à 0': 'Toto pole musí byť väčšie ako 0',
  'Maison/Déco': 'Domov/Dekor',
  'Vous avez': 'Máte',
  'Cette ligne de commande ne peut pas être supprimée car une partie de ses textes a été livré':
    'Tento príkazový riadok nemožno odstrániť, pretože časť jeho textu bola doručená',
  'vérifiez votre email et cliquez sur le lien pour continuer !':
    'skontrolujte svoj e-mail a kliknite na odkaz pre pokračovanie!',
  Sarde: 'Sardínia',
  'Note rédacteur': 'Poznámka redakcie',
  'Uighur ; Uyghur': 'Ujguri; Ujguri',
  'Commandes intelligentes': 'Smart príkazy',
  'Commandes avec révision': 'Smart + review príkazy',
  'Type de contenu': 'Typ obsahu',
  'Fiche produit': 'Produktový list',
  GMB: 'GMB',
  'STORE LOCATOR': 'STORE LOCATOR',
  'Rédaction premium': 'Prémiová redakcia',
  'Rédaction avec révision': 'Smart + review redakcia',
  'Rédaction intelligente': 'Smart redakcia',
  PREMIUM: 'PREMIUM',
  Type: 'Typ',
  Editer: 'Upravit',
  'Tous les types': 'Všechny typy',
  Smart: 'Smart',
  'Smart + review': 'Smart + review',
  'Factures traitées': 'Spracované faktúry',
  'Factures à valider': 'Faktúry na overenie',
  'Impossible de générer la facture ! Veuillez réessayer':
    'Nemožno vygenerovať faktúru! Skúste to prosím znova',
  'Récapitulatif de facturation': 'Súhrn fakturácie',
  'Demander une modification': 'Požiadať o zmenu',
  'Valider et envoyer la facture': 'Schváliť a odoslať faktúru',
  'Demande de modification': 'Žiadosť o zmenu',
  'La facture a été validé avec succès!': 'Faktúra bola úspešne schválená!',
  "Oups un erreur c'est produit!": 'Ups, vyskytla sa chyba!',
  'Nombre de texte': 'Počet textov',
  'Nombre de mot par texte :': 'Počet slov na text:',
  Paiement: 'Platba',
  Virement: 'Prevod',
  Délai: 'Lehota',
  'Référence de votre facture': 'Referencia vašej faktúry',
  'Coût au mot': 'Náklady na slovo',
  'Montant global de la commande': 'Celková suma objednávky',
  'Votre facture': 'Vaša faktúra',
  'Importer votre facture': 'Importovať vašu faktúru',
  'Pas de fichier disponible': 'Žiadny dostupný súbor',
  Télécharger: 'Stiahnuť',
  Relancer: 'Opätovne odoslať',
  'Générer la facture': 'Generovať faktúru',
  Payée: 'Zaplatené',
  'En cours de paiement': 'V procese platby',
  'En cours de validation': 'V procese schvaľovania',
  'A envoyer': 'Odoslať',
  'Modification demandée': 'Požadovaná zmena',
  'Référence de facture est obligatoire': 'Referencia faktúry je povinná',
  'Le coût au mot doit être un nombre': 'Náklady na slovo musia byť číslom',
  'Le coût au mot doit être un nombre positif':
    'Náklady na slovo musia byť kladné číslo',
  'Le coût au mot est obligatoire': 'Náklady na slovo sú povinné',
  'Le montant global de la commande doit être un nombre':
    'Celková suma objednávky musí byť číslom',
  'Le montant global de la commande doit être un nombre positif':
    'Celková suma objednávky musí byť kladné číslo',
  'Le montant global de la commande est obligatoire':
    'Celková suma objednávky je povinná',
  'Bill reference existe déja , veuillez réessayer':
    'Referencia faktúry už existuje, skúste to prosím znova',
  'La facture a été envoyée avec succès!': 'Faktúra bola úspešne odoslaná!',
  'Montant estimé': 'Odhadovaná suma',
  Action: 'Akcia',
  Facture: 'Faktúra',
  Statut: 'Stav',
  Factures: 'Faktúry',
  'Valider la facture': 'Ověriť faktúru',
  'La relance a été envoyée avec succès !': 'Upomienka bola úspešne odoslaná!',
  'La demande de modification a été envoyée avec succès !':
    'Žiadosť o úpravu bola úspešne odoslaná!',
  'Le cout est trop élevé': 'Náklady sú príliš vysoké',
  'Le montant global de la commande est trop élevé ':
    'Celková suma objednávky je príliš vysoká',
  jours: 'dni',
  'Factures à envoyer': 'Faktúry na odoslanie',
  'Valider le paiement': 'Potvrdenie platby',
  'Générer une facture': 'Generovať faktúru',
  'Montant rédacteur': 'Čiastka pre autora',
  'Montant estimé par Typix': 'Odhadovaná suma od Typix',
  'Voir la modification': 'Zobraziť úpravu',

  'Tag de Titre': 'Tag názvu',
  'Meta description': 'Meta popis',
  'Nom du produit': 'Názov produktu',
  Avantages: 'Výhody',
  'Description détaillée': 'Podrobný popis',
  'En cours de modification': 'V procese úprav',
  'Mes Factures': 'Moje faktúry',
  'En attente de validation': 'Čaká na schválenie',
  'les valeurs négatives ne sont pas autorisées':
    'Záporné hodnoty nie sú povolené.',
  'Texte Seulement': 'Len text',
  'Traduction Seulement': 'Len preklad',
  'La relance peut être faite 20 jours après la soumissions de la facture':
    'Pripomienka môže byť odoslaná 20 dní po odoslaní faktúry',
  Instructions: 'Inštrukcie',
  'Votre avis pour la commande': 'Váš názor na objednávku',
  'Votre évaluation est précieuse pour nous ! Merci !':
    'Vaše hodnotenie je pre nás cenné! Ďakujeme!',
  "Une erreur s'est produite lors de la soumission de votre évaluation. Veuillez réessayer ultérieurement!":
    'Pri odosielaní vášho hodnotenia došlo k chybe. Prosím, skúste to neskôr znova!',
  Evaluer: 'Hodnotiť',
  Commentaire: 'Komentár',
  'Votre commantaire': 'Váš komentárVáš komentár',
  Note: 'Hodnotenie',
  'Votre retour est précieux pour nous et nous aide à améliorer nos services.':
    'Vaša spätná väzba je pre nás cenná a pomáha nám zlepšovať naše služby.',
  'Verifier la facture': 'Overte faktúru',

  ' Votre retour est précieux pour nous et nous aide à améliorer nos services. Merci de partager votre évaluation avec nous!':
    'Vaša spätná väzba je pre nás cenná a pomáha nám zlepšovať naše služby. Ďakujeme, že ste svoje hodnotenie s nami zdieľali!',
  'Notes rédacteur': 'Poznámky redaktora',
  'Avis client': 'Hodnotenia zákazníkov',
  'Pas de commantaire': 'Žiadny komentár',
  "Qu'avez vous pensé de votre commande?": 'Čo si myslíte o vašej objednávke?',
  'Evaluation de la commande': 'Hodnotenie objednávky',
  'Rédiger un commentaire': 'Napísať komentár',
  'Contribuez à nous permettre de vous proposer les services de rédaction de la plus haute qualité en partageant vos commentaires.':
    'Pomáhajte nám ponúkať vám služby písania najvyššej kvality tým, že zdieľate vaše pripomienky.',
  'Que pouvons-nous améliorer?': 'Čo môžeme zlepšiť?',
  Continuer: 'Pokračovať',
  'Le dernier texte a été validé avec succès':
    'Posledný text bol úspešne schválený',
  'Rechercher par rédacteur, commandes, et montant':
    'Vyhľadávať podľa redaktora, objednávok a sumy',
  obligatoire: 'povinný',
  'Note admin': 'Poznámka administrátora',
  'Note client': 'Poznámka klienta',
  Partie: 'Časť',
  'Vous venez de valider tous les contenus rédigés par ':
    'Práve ste schválili všetok obsah napísaný ',
  "Qu'en avez vous pensé": 'Čo si o tom myslíte',
  'La facture a été générée avec succès !':
    'Faktúra bola úspešne vygenerovaná!',
  'La taille du titre et/ou la méta-description est trop courte':
    'Dĺžka nadpisu a/alebo meta popisu je príliš krátka',

  'Demande de révision globale': 'Vyžadovať celkovú revíziu',
  Valider: 'Overiť',
  'Obligatoire en cas d’optimisation ou traduction':
    'Povinné v prípade optimalizácie alebo prekladu',
  'Mots clés Bourrées': 'Keyword stuffing',
  'Votre commentaire...': 'Váš komentár...',
  'Ex : "Contenus de très bonne qualité': 'Napr.: "Obsah veľmi dobrej kvality"',
  "pouvez-vous s'il vous plaît reprendre ce/tte rédacteur/rice pour les prochaines commandes?":
    'Mohli by ste, prosím, zvážiť tohto autora pre budúce objednávky?',
  'Ce bouton est pour informer le client que le texte initial, contenait déjà du keyword stuffing':
    'Tento tlačidlo slúži na informovanie klienta, že pôvodný text už obsahoval nadmerné použitie kľúčových slov',
  'Maillage Interne': 'Internal link',
  'Modifier le texte': 'Upravte text',
  Ajouter: 'Pridať',
  'Demande examen': 'Žiadosť o skúšku',
  'Recherche de mots clés à haute densité': 'Hľadanie keyword stuffing',
  'Oups, il semblerait qu’il y ait du keyword stuffing dans votre texte : ':
    'Oops, zdá sa, že vo vašom texte je keyword stuffing: ',
  'Oups, il semblerait qu’il y ait du keyword stuffing (trop d’occurrences d’un ou plusieurs mots clés) dans le texte initial.':
    'Oops, zdá sa, že vo vašom pôvodnom texte je keyword stuffing (príliš veľa výskytov jedného alebo viacerých kľúčových slov).',
  'Merci de vous assurer que la densité des mots clés soit optimisée pour le SEO (une occurrence maximum tous les 50 mots).':
    'Prosím, uistite sa, že hustota kľúčových slov je optimalizovaná pre SEO (maximálne jeden výskyt každých 50 slov).',
  'Pour cela merci de ne pas retoucher le texte existant et de bien vouloir envoyer une demande d’examen au support.':
    'Pre toto, prosím neupravujte existujúci text a láskavo pošlite žiadosť o skúšku podpore.',
  'Votre demande a été bien envoyée !': 'Vaša žiadosť bola úspešne odoslaná!',
  "Il y a eu un problème lors de l'envoi de votre demande":
    'Pri odosielaní vašej žiadosti nastal problém.',
  'Veuillez attendez pour le  keyword Stuffing analyse':
    'Prosím, počkajte na analýzu keyword stuffing.',
  'Inserez le maillage interne': 'Vložte vnútornú sieť',
  'Merci de vous assurer que la densité des mots clés soit optimisée pour le SEO (une occurrence maximum tous les 50 mots).\nSi vous constatez un terme pouvant avoir plusieurs significations (Exemple : Former / Formation/ Forme ou Matelas/Matelassé) , vous pouvez faire une demande d’examen auprès du support.':
    'Uistite sa, že hustota kľúčových slov je optimalizovaná pre SEO (maximálne jedno výskyt na každých 50 slov).\nAk narazíte na termín, ktorý môže mať viacero významov (Príklad: Former / Formation / Form alebo Matelas / Matelassé), môžete požiadať o preskúmanie u podpory.',
  'Mots clés à haute densité dans le texte actuel':
    'Kľúčové slová s vysokou hustotou v aktuálnom texte',
  'Mots clés à haute densité dans le texte initial':
    'Kľúčové slová s vysokou hustotou v pôvodnom texte',
  'Texte en cours de rédaction !': 'Text sa píše !',
  'Êtes-vous sûr(e) de vouloir valider le texte ? Assurez-vous d’abord d’avoir enregistré les modifications.':
    'Ste si istý/á, že chcete potvrdiť text? Najprv sa uistite, že ste uložil(a) zmeny.',
  "Information dernière minute ! Ton stock de crédits sera bientôt épuisé :( Pour anticiper, n'hésite pas à demander dès maintenant plus de crédits pour terminer ta commande.":
    'Informácia v poslednej chvíli! Tvoj kreditný zostatok sa čoskoro vyčerpá :( Pre anticipáciu neváhaj požiadať o viac kreditov už teraz, aby si dokončil(a) objednávku.',
  'Voulez-vous confirmer l’URL actuelle ou la modifier ?':
    'Chcete potvrdiť aktuálnu URL adresu alebo ju zmeniť?',
  'Veuillez fournir une URL valide': 'Prosím, zadajte platnú URL adresu.',
  'Merci de bien vouloir indiquer ci-dessous l’url de la page où a été intégré le contenu:':
    'Prosím, uveďte nižšie URL adresu stránky, kde bol obsah integrovaný:',
  'Contenu en ligne': 'Online obsah',
  'Télécharger le document de révision': 'Stiahnite si revízny dokument',
  'Date d’envoi': 'Dátum odoslania',
  Information: 'Informácie',
  Enregistrer: 'Uložiť',
  'Textes en ligne': 'Texty online',
  'Livrer les textes': 'Doručiť texty',
  Indisponible: 'Nedostupné',
  Disponible: 'Dostupné',
  'Les textes ont été livrés avec succès.': 'Texty boli úspešne doručené.',
  'Le texte a été livré avec succès.': 'Text bol úspešne doručený.',
  'Les textes ont été validés avec succès.': 'Texty boli úspešne overené.',
  'Le texte a été validé avec succès.': 'Text bol úspešne overený.',
  'Voulez-vous vraiment valider les Textes de la commande':
    'Naozaj chcete overiť texty objednávky?',
  'Voulez-vous vraiment livrer les Textes de la commande':
    'Naozaj chcete doručiť texty objednávky?',
  'Fiche produit longue': 'Dlhý produktový list',
  'Fiche produit courte': 'Krátky produktový list',
  'Un texte est trop long et ne peut pas être exporté sur Excel':
    'Text je príliš dlhý a nedá sa exportovať do Excelu.',
  'Mettre un texte en brouillon en livré': 'Zmeniť text z konceptu na doručený',
  'Remettre un texte validé à livré': 'Zmeniť overený text na doručený',
  'Invalider le texte': 'Zneplatniť text',
  'Je souhaiterai modifier mon mot de passe : comment faire ?':
    'Chcel(a) by som zmeniť svoje heslo: ako na to?',
  'Comment mettre un contenu en bon pour livraison ?':
    'Ako nastaviť obsah pripravený na doručenie?',
  'Je ne peux pas mettre mon contenu en « brouillon »':
    'Nemôžem uložiť svoj obsah ako „koncept“',
  'Typix n’a pas enregistré mes dernières modifications':
    'Typix neuložil moje posledné zmeny',
  'Comment rédiger une création de contenu ?': 'Ako napísať tvorbu obsahu?',
  'Comment rédiger une optimisation de contenu ?':
    'Ako napísať optimalizáciu obsahu?',
  'Je ne vois plus ma commande – que faire ?':
    'Nevidím svoju objednávku – čo robiť?',
  'Comment générer une facture ?': 'Ako vytvoriť faktúru?',
  'Je ne peux pas generer ma facture : que faire ?':
    'Nemôžem vytvoriť svoju faktúru: čo robiť?',
  'Tuto process Typix global': 'Globálny proces Typix - tutoriál',
  'Tuto mise en ligne des contenus': 'Ako nahrať obsah - tutoriál',
  'Modification mot de passe': 'Zmena hesla',
  'Le test ne me convient pas': 'Test mi nevyhovuje',
  'Comment valider un contenu dans Typix ?': 'Ako validovať obsah v Typix?',
  'Tuto Facturation': 'Návod na fakturáciu',
  'Le statut du contenu a bien été modifié': 'Stav obsahu bol úspešne zmenený',
  'Le gap sémantique de ce mot clé n’a pas encore été validé, veuillez contacter le support Typix : support-typix@pixalione.com':
    'Sémantická medzera tohto kľúčového slova ešte nebola validovaná, kontaktujte podporu Typix: support-typix@pixalione.com',
  'Ce mot clé n’existe pas sur MONETORING, veuillez vérifier avec le support Typix : support-typix@pixalione.com':
    'Toto kľúčové slovo neexistuje na MONETORING, skontrolujte s podporou Typix: support-typix@pixalione.com',
  'Récupérer le Gap': 'Získať medzeru',
  'Le statut du contenu a bien été modifié': 'Stav obsahu bol úspešne zmenený',
  'Le gap sémantique de ce mot clé n’a pas encore été validé, veuillez contacter le support Typix : support-typix@pixalione.com':
    'Sémantická medzera tohto kľúčového slova ešte nebola validovaná, kontaktujte podporu Typix: support-typix@pixalione.com',
  'Ce mot clé n’existe pas sur MONETORING, veuillez vérifier avec le support Typix : support-typix@pixalione.com':
    'Toto kľúčové slovo neexistuje na MONETORING, skontrolujte s podporou Typix: support-typix@pixalione.com',
  'Récupérer le Gap': 'Získať medzeru',
  'Traduction premium': 'Prémiový preklad',
  'Rédaction et/ou Traduction intelligente':
    'Inteligentné písanie a/alebo preklad',
  'Rédaction et/ou Traduction premium': 'Prémiové písanie a/alebo preklad',
  'Rédaction et/ou Traduction avec révision':
    'Písanie a/alebo preklad s revíziou',
  'Rédaction et/ou Traduction intelligente':
    'Inteligentné písanie a/alebo preklad',
  'Traduction avec révision': 'Preklad s revíziou',
  'Traduction intelligente': 'Inteligentný preklad',
  'Le nombre de mots commandé est supérieur à celui vendu':
    'Počet objednaných slov je vyšší ako predaných',
  'Rédaction et/ou Traduction premium': 'Prémiové písanie a/alebo preklad',
  'Rédaction et/ou Traduction smart + review':
    'Inteligentné písanie a/alebo preklad + revízia',
  'Rédaction et/ou Traduction smart': 'Inteligentné písanie a/alebo preklad',
  'Nom Silo': 'Názov Silo',
  'Remettre en brouillon': 'Vrátiť do konceptu',
  'Bon pour Livraison Vérifié': 'Overené na doručenie',
  'Voulez-vous vraiment remettre en brouillon les Textes de la commande':
    'Naozaj chcete vrátiť texty objednávky do konceptu',
  'La vérification du texte a été annulée.': 'Overenie textu bolo zrušené.',
  'Voulez-vous vraiment annuler la vérification du texte?':
    'Naozaj chcete zrušiť overenie textu?',
  'Etes-vous sûr(e) de vouloir regénérer tous les contenus ?':
    'Ste si istý/á, že chcete znovu vygenerovať všetok obsah?',
  'Je suis sûr(e)': 'Som si istý/á',
  'Non, revenir en arrière': 'Nie, vrátiť sa späť',
};
