import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import axiosPrivate from 'config/axiosPrivate';

import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  adminWriterDisplaySelector,
  setWirterName,
  setWriterId as setWriterIdState,
} from 'store/Admin/WriterDisplay';

import { CustomSWitch } from 'components/shared/CustomSwitch';
import { CustomAutocompleteMultipleChoice } from 'components/shared/DropDown/CustomAutocompleteMultipleChoice';
import { CustomChipsAutocomplete } from 'components/shared/DropDown/CustomChipsAutocomplete';

import ErrorSuccesModal from 'components/shared/modal/ErrorSuccesModal';
import ConfirmationModal from 'components/shared/modal/ConfirmationModal';
import { disabledInput } from 'components/shared/disabledClasses';
import { Box, Rating, Tooltip } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import { lower } from 'utils/StringUtils';
import { PWD_REGEX, EMAIL_REGEX, PHONENUMBER_REGEX } from 'utils/Regex';
import { MAIN_LANGUAGES, ROLES } from 'utils/Constants';

import 'components/Admin/writers.css';
import { Loader } from 'components/shared/Loader';
import ErrorComponent from 'components/shared/ErrorComponent';
import { UpDownInputArrows } from 'components/shared/UpDownInputArrows';

import { fetchWriterOrdersList } from 'store/Admin/WriterDisplay/features';

const API = {
  updateWriter: '/user/',
  deleteWriter: '/user/',
  getAvailableThematics: '/thematic/all',
  getAvailableLanguages: '/language/all',
};

export default function WriterDisplay() {
  let { writer, isLoading, isError } = useSelector(adminWriterDisplaySelector);
  const history = useHistory();
  const { t } = useTranslation();
  const [writerId, setWriterId] = useState('');
  const [mail, setMail] = useState('');
  const [name, setName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [limit, setLimit] = useState(0);
  const [available, setAvailable] = useState(true);
  const [minRate, setMinRate] = useState(0);
  const [maxRate, setMaxRate] = useState(0);

  const [address, setAddress] = useState('');
  const [companyName, setCompanyName] = useState('');

  const [availableThematicsList, setAvailableThematicsList] = useState([]);
  const [availableLanguagesList, setAvailableLanguagesList] = useState([]);

  const [mainWriterLanguages, setMainWriterLanguages] = useState([]);
  const [minorWriterLanguages, setMinorWriterLanguages] = useState([]);
  const [writerThematics, setWriterThematics] = useState([]);

  const [disabledForm, setDisabledForm] = useState(true);

  const [errorSuccess, setErrorSuccess] = useState(false);
  const [errorSuccessMsg, setErrorSuccessMsg] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);
  const [confirmation, setConfirmation] = useState(false);
  const [confirmationMsg, setConfirmationMsg] = useState('');

  const dispatch = useDispatch();

  useEffect(() => {
    window.dispatchEvent(new Event('locationchange'));
    document.title = t('Typix - Fiche rédacteur');
    axiosPrivate
      .get(API.getAvailableLanguages)
      .then((res) => setAvailableLanguagesList(res.data));
    axiosPrivate
      .get(API.getAvailableThematics)
      .then((res) => setAvailableThematicsList(res.data));
  }, [t]);

  useEffect(() => {
    if (writer) {
      setWriterId(writer.id);
      setMail(writer.mail);
      setName(writer.name);
      setPhoneNumber(writer.phoneNumber);
      setCompanyName(writer.companyName);
      setAddress(writer.address);
      setLimit(writer.limit);
      setMinRate(writer.minRate);
      setMaxRate(writer.maxRate);
      setAvailable(writer.isAvailable);
      setWriterThematics(writer.thematics);
      setMainWriterLanguages(
        writer.languages.filter((x) => MAIN_LANGUAGES.includes(x.language))
      );
      setMinorWriterLanguages(
        writer.languages.filter((x) => !MAIN_LANGUAGES.includes(x.language))
      );
    }
  }, [writer]);

  const checkPasswordValid = useCallback(() => {
    return !password || PWD_REGEX.test(password);
  }, [password]);

  const checkValidForm = useCallback(() => {
    if (
      !mail ||
      !phoneNumber ||
      !name ||
      !companyName ||
      !address ||
      writerThematics.length === 0 ||
      (mainWriterLanguages.length === 0 && minorWriterLanguages.length === 0)
    ) {
      setDisabledForm(true);
      return;
    }

    if (mail.length > 0 && !mail.match(EMAIL_REGEX)) {
      setDisabledForm(true);
      return;
    }

    return setDisabledForm(!checkPasswordValid());
  }, [
    address,
    checkPasswordValid,
    companyName,
    mail,
    mainWriterLanguages,
    minorWriterLanguages,
    name,
    phoneNumber,
    writerThematics,
  ]);

  useEffect(() => {
    checkValidForm();
  }, [
    checkValidForm,
    mail,
    name,
    password,
    phoneNumber,
    companyName,
    address,
    writerThematics,
    mainWriterLanguages,
    minorWriterLanguages,
  ]);

  const callbackFromThematicMultiSelect = (newList) => {
    setWriterThematics(newList);
  };

  const callbackFromLanguagesMultiSelect = (newList) => {
    setMinorWriterLanguages(newList);
  };

  const findLanguageIndex = (language) => {
    return mainWriterLanguages.findIndex((element) =>
      lower(element.language).includes(lower(language))
    );
  };

  const checkLanguage = (language) => {
    let index = findLanguageIndex(language);
    let array = [...mainWriterLanguages];
    if (index > -1) {
      array.splice(index, 1);
    } else {
      let item = availableLanguagesList.find((element) =>
        lower(element.language).includes(lower(language))
      );
      array.push(item);
    }

    setMainWriterLanguages(array);
  };

  const handleSubmit = () => {
    let body = {
      password: !password ? null : password,
      id: writer.id,
      mail,
      name,
      phoneNumber,
      companyName,
      isAvailable: available,
      address,
      limit,
      languages: mainWriterLanguages.concat(minorWriterLanguages),
      thematics: writerThematics,
      minRate: parseFloat(minRate),
      maxRate: parseFloat(maxRate),
      role: ROLES.WRITER,
    };

    axiosPrivate
      .put(API.updateWriter, body, { params: { mail: writer.mail } })
      .then(() => {
        setIsDeleted(false);
        setErrorSuccessMsg(
          `${t('Le rédacteur')} ${mail} ${t('a été mis à jour avec succès')} !`
        );
        setIsSuccess(true);
        setErrorSuccess(true);
      })
      .catch((e) => {
        setIsDeleted(false);
        switch (e.response.status) {
          case 409:
            setErrorSuccessMsg(
              `${t("Un utilisateur existe déjà avec l'email")} ${mail}`
            );
            break;
          default:
            setErrorSuccessMsg(t("Oups....! Une erreur s'est produite"));
            break;
        }
        setIsSuccess(false);
        setErrorSuccess(true);
      });
  };

  const deleteWriter = () => {
    axiosPrivate
      .delete(API.deleteWriter + writer.mail, {
        params: { role: ROLES.WRITER },
      })
      .then(() => {
        setIsDeleted(true);
        setErrorSuccessMsg(
          `${t('Le rédacteur')} ${mail} ${t('a été supprimé avec succès')} !`
        );

        setIsSuccess(true);
        setErrorSuccess(true);
      })
      .catch((e) => {
        setIsDeleted(false);
        setErrorSuccessMsg(
          e.response.status === 409
            ? `${t('Vous ne pouvez pas supprimer le rédacteur')} ${mail} ,${t(
                'car celui-ci a déjà rédigé des textes'
              )}.`
            : t("Oups....! Une erreur s'est produite")
        );
        setIsSuccess(false);
        setErrorSuccess(true);
      });
  };

  if (isLoading) return <Loader />;

  if (isError) return <ErrorComponent />;

  return (
    <div className="content-center">
      <div className="box-header-top header-info">
        <span onClick={history.goBack} className="bt-circle me-3" />
        <h1>{name}</h1>
      </div>
      <div className="row">
        <div className="col-12 col-md-10 box-screen">
          <div className="box-img-bg bg-8" />
          <div className="box-img-bg bg-7" />
          <div className="box-img-bg bg-9" />
          <div className="header-title">
            {t('Fiche du rédacteur')}
            <span
              className="bt-icon-trash"
              onClick={() => {
                setConfirmationMsg(
                  `${t('Voulez-vous vraiment supprimer le rédacteur')} ${
                    writer.mail
                  } ?`
                );
                setConfirmation(true);
              }}
            >
              <img src="/Images/icon-trash.png" alt="icon-trash" />
            </span>
          </div>
          <div className="box-wrapper">
            <div className="row">
              <div className="col-12 col-md-4">
                <div className="row row-mb">
                  <div className="col-12 col-md-12">
                    <div className="label-title">{t('Mail')}</div>
                    <div>
                      <input
                        value={mail}
                        onChange={(e) => setMail(e.target.value)}
                        type="text"
                        className="form-control form-ct"
                      />
                    </div>
                  </div>
                </div>
                <div className="row row-mb">
                  <div className="col-12 col-md-12">
                    <div className="label-title">{t('Mot de passe')}*</div>
                    <div className="input-group input-password">
                      <input
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        type={showPassword ? 'text' : 'password'}
                        className="form-control"
                      />
                      <span
                        className="input-group-text"
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? (
                          <img
                            src="/Images/icon-eye-black.png"
                            alt="black-eye"
                          />
                        ) : (
                          <img src="/Images/icon-eye-gray.png" alt="grey-eye" />
                        )}
                      </span>
                    </div>
                    <div className="text-more mt-1">
                      *
                      {t(
                        'Avoir au moins 8 caractères avec une lettre majuscule, un chiffre, un symbole et ne pas être identique au nom.'
                      )}
                    </div>
                  </div>
                </div>
                <div className="row row-mb">
                  <div className="col-12 col-md-12">
                    <div className="label-title">{t('Adresse')}</div>
                    <div>
                      <input
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                        type="text"
                        className="form-control form-ct"
                      />
                    </div>
                  </div>
                </div>
                <div className="row row-mb">
                  <div className="col-12 col-md-12">
                    <div className="label-title">{t('Société')}</div>
                    <div>
                      <input
                        type="text"
                        value={companyName}
                        onChange={(e) => setCompanyName(e.target.value)}
                        className="form-control form-ct"
                        style={{ width: 250 }}
                      />
                    </div>
                  </div>
                </div>
                <div className="row row-mb">
                  <div className="col-12 col-md-12">
                    <div className="label-title">{t('Analyse/Limite')}</div>
                    <div
                      className="form-control form-ct"
                      style={{ width: 250 }}
                    >
                      <span
                        style={{
                          backgroundColor: 'rgb(233, 236, 239)',
                          padding: '0 10px',
                          borderRadius: '15px',
                        }}
                      >
                        {' '}
                        {writer.analysis > limit ? 0 : writer.analysis}
                      </span>{' '}
                      /
                      <input
                        type="number"
                        value={limit}
                        min="0"
                        style={{ width: '75px' }}
                        className="white-input"
                        onChange={(e) => setLimit(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="row row-mb">
                  <div className="col-12 col-md-4">
                    <div className="label-title">
                      {t('Tarif/Mot')}: {t('Min')}
                    </div>
                    <div className="number-wrapper input-group input-grl">
                      <span
                        style={{ paddingRight: '5px' }}
                        className="input-group-text"
                      >
                        €
                      </span>
                      <input
                        type="number"
                        min="0"
                        max="0.99"
                        value={minRate}
                        onChange={(e) => {
                          setMinRate(e.target.value);
                        }}
                        className={
                          parseFloat(minRate) >= 1
                            ? 'form-control form-control-error form-ct'
                            : 'form-control form-control-valid form-ct'
                        }
                      />
                      <UpDownInputArrows disabled={false} />
                    </div>
                    {parseFloat(minRate) >= 1 ? (
                      <p className="error-note">
                        {t('Ce champs doit être sup à 0 et inférieur à 1')}
                      </p>
                    ) : null}
                  </div>
                  <div className="col-12 col-md-4">
                    <div className="label-title">{t('Max')}</div>
                    <div className="number-wrapper input-group input-grl">
                      <span
                        style={{ paddingRight: '5px' }}
                        className="input-group-text"
                      >
                        €
                      </span>
                      <input
                        value={maxRate}
                        type="number"
                        min="0"
                        max="0.99"
                        onChange={(e) => {
                          setMaxRate(e.target.value);
                        }}
                        className={
                          parseFloat(maxRate) >= 1
                            ? 'form-control form-control-error form-ct'
                            : 'form-control form-control-valid form-ct'
                        }
                      />
                      <UpDownInputArrows disabled={false} />
                    </div>
                    {parseFloat(maxRate) >= 1 ? (
                      <p className="error-note">
                        {t('Ce champs doit être sup à 0 et inférieur à 1')}
                      </p>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-8">
                <div className="row row-mb">
                  <div className="col-12 col-md-5">
                    <div className="label-title">{t('Prénom/Nom')}</div>
                    <div>
                      <input
                        type="text"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        className="form-control form-ct"
                      />
                    </div>
                  </div>
                  <div className="col-12 col-md-5">
                    <div className="label-title">{t('Téléphone')}</div>
                    <div>
                      <input
                        type="text"
                        value={phoneNumber}
                        onChange={(e) => {
                          if (
                            e.target.value === '' ||
                            PHONENUMBER_REGEX.test(e.target.value)
                          ) {
                            setPhoneNumber(e.target.value);
                          }
                        }}
                        className="form-control form-ct"
                      />
                    </div>
                  </div>
                  <div className="col-12 col-md-2">
                    <div className="label-title">ID</div>

                    <input
                      style={disabledInput}
                      type="text"
                      disabled
                      value={writerId}
                      className="form-control form-ct"
                    />
                  </div>
                </div>

                <div className="row row-mb">
                  <div className="col-12 col-md-12">
                    <div className="label-title">{t('Thématiques')}</div>
                    <CustomChipsAutocomplete
                      callbackFromMultiSelect={callbackFromThematicMultiSelect}
                      value={writerThematics}
                      disabled={!availableThematicsList}
                      list={availableThematicsList}
                      setValue={(option) => setWriterThematics(option)}
                      getOptionLabel={(option) => {
                        return t(option.thematic);
                      }}
                    />
                  </div>
                </div>
                <div className="row row-mb mt-4">
                  <div className="col-12 col-md-12">
                    <div className="label-title mb-2">{t('Langues')} : </div>
                    <div className="box-wrapper-cols">
                      {MAIN_LANGUAGES.slice(0, 3).map((language, key) => (
                        <div key={key} className="cols">
                          <div className="box-checkbox box-list-check">
                            <input
                              checked={findLanguageIndex(language) > -1}
                              onChange={() => checkLanguage(language)}
                              className="form-check-input"
                              type="checkbox"
                            />
                            <div> {t(language)}</div>
                          </div>
                        </div>
                      ))}
                    </div>

                    <div className="box-wrapper-cols mt-4">
                      {MAIN_LANGUAGES.slice(3).map((language, key) => (
                        <div key={key} className="cols">
                          <div className="box-checkbox box-list-check">
                            <input
                              checked={findLanguageIndex(language) > -1}
                              onChange={() => checkLanguage(language)}
                              className="form-check-input"
                              type="checkbox"
                            />
                            <div> {t(language)}</div>
                          </div>
                        </div>
                      ))}
                      <div className="cols">
                        <CustomAutocompleteMultipleChoice
                          callbackFromMultiSelect={
                            callbackFromLanguagesMultiSelect
                          }
                          value={minorWriterLanguages}
                          disabled={!availableLanguagesList}
                          list={availableLanguagesList.filter(
                            (x) =>
                              !MAIN_LANGUAGES.map((lan) => lower(lan)).includes(
                                lower(x.language)
                              )
                          )}
                          setValue={(option) => setMinorWriterLanguages(option)}
                          getOptionLabel={(option) => {
                            return t(option.language);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row row-mb">
                  <div className="col-12 col-md-6">
                    <div className="label-title mb-2">
                      {t('Disponibilité')} :
                    </div>

                    <div className="box-list-check">
                      <CustomSWitch
                        value={available}
                        toggleValue={() => setAvailable(!available)}
                      />
                      <div>
                        {available ? t('disponible') : t('indisponible')}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-12 d-flex justify-content-between">
                  <div>
                    <div className="label-title mb-2">
                      {t('Note rédacteur')} :
                    </div>
                    <div className="box-line-star">
                      <span className="txt-dark">{t('Global')} : </span>
                    </div>{' '}
                    <Box sx={{ marginLeft: 1, marginRight: 1, fontSize: 16 }}>
                      <Rating
                        name={`client rating`}
                        defaultValue={writer.note}
                        readOnly
                        precision={0.5}
                        emptyIcon={
                          <StarIcon
                            style={{ opacity: 0.3 }}
                            fontSize="inherit"
                          />
                        }
                        icon={<StarIcon style={{ fontSize: 'inherit' }} />}
                      />{' '}
                      <Tooltip
                        title={
                          Number.isInteger(writer.note)
                            ? writer.note
                            : writer.note?.toFixed(2)
                        }
                        placement="top"
                      >
                        <button
                          type="button"
                          className="btn-circle"
                          style={{ marginLeft: '5px' }}
                        ></button>
                      </Tooltip>
                    </Box>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="box-button-bt ms-2 ">
              <div>
                {disabledForm ? (
                  <button className="bt-submit-disabled-y">
                    {' '}
                    {t('Sauvegarder')}{' '}
                  </button>
                ) : (
                  <button className="bt-submit-y" onClick={handleSubmit}>
                    {' '}
                    {t('Sauvegarder')}{' '}
                  </button>
                )}
              </div>
            </div>

            <div className="box-button-bt ">
              <div>
                <button
                  className="bt-submit-y"
                  onClick={() => {
                    dispatch(setWirterName(name));
                    dispatch(fetchWriterOrdersList(writerId));
                    dispatch(setWriterIdState(writerId));

                    history.push('/Admin/commandesDuRedacteur');
                  }}
                >
                  {t('Voir les commandes')}
                </button>
              </div>
            </div>
          </div>
        </div>

        <ErrorSuccesModal
          open={errorSuccess}
          message={errorSuccessMsg}
          handleClose={() =>
            isDeleted ? history.goBack() : setErrorSuccess(false)
          }
          success={isSuccess}
          toggle={() => setErrorSuccess(!errorSuccess)}
        />

        <ConfirmationModal
          open={confirmation}
          message={confirmationMsg}
          handleClose={() => setConfirmation(false)}
          executeAction={deleteWriter}
        />
      </div>
    </div>
  );
}
