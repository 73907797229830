export const sweedishTranslation = {
  'Les lignes de commandes ont été mises à jour avec succès !':
    'Kommandoraderna har uppdaterats framgångsrikt!',
  'Le texte a été enregistré avec succès':
    'Texten har registrerats framgångsrikt.',
  'La traduction a été enregistrée avec succès':
    'Översättningen har sparats framgångsrikt',
  'Texte + Traduction enregistré avec succès':
    'Text + Översättning har sparats framgångsrikt',
  'Nombre de mots': 'Antal ord',
  Lingala: 'Lingala',
  'Prénom/Nom du client': 'Kundens förnamn och efternamn',
  Bashkir: 'Bashkir',
  "La date doit être ultérieure à aujourd'hui.":
    'Datumet måste vara senare än idag.',
  'Contenu rédigé': 'Innehåll skrivet',
  'Nom/prénom': 'Efternamn/förnamn',
  'Mot de passe incorrect': 'Felaktigt lösenord',
  Institutions: 'Institutioner',
  'Veuillez donner un descriptif au document téléchargé':
    'Ge en beskrivning av det nedladdade dokumentet',
  'Sichuan Yi ; Nuosu': 'Sichuan Yi; Nuosu',
  Suivant: 'Nästa',
  Kannada: 'Kannada',
  'Séparer les mots clés par un "enter"': 'Separera nyckelord med ett "enter".',
  Allemand: 'Tyska',
  Sango: 'Sango',
  'Min.': 'Min.',
  'un email contenant un lien de réinitialisation du mot de passe a été envoyé à votre email':
    'ett e-postmeddelande med en länk för återställning av lösenordet har skickats till din e-postadress',
  'Chef edito': 'Chefförfattare',
  Croate: 'Kroatiska',
  'Nb de textes': 'Antal texter',
  Violet: 'Violett',
  'Ce champs doit être sup ou égale nombre de texte':
    'Detta fält måste vara större än eller lika med antalet text',
  'a été activé avec succès': 'har aktiverats med framgång.',
  Népalais: 'Nepalesiska',
  'Nombre de FAQ': 'Antal vanliga frågor och svar',
  Urdu: 'Urdu',
  'Analyses restantes': 'Återstående analyser',
  Indonésien: 'Indonesiska',
  Breton: 'Bretonskt',
  Livrés: 'Levererad',
  Associations: 'Föreningar',
  'Le client': 'Kunden',
  'Révision demandée': 'Begärd översyn',
  "Une erreur s'est produite, veuillez réessayer":
    'Ett fel har inträffat, försök igen.',
  'Mise à jour non autorisée car des textes ont été livrés':
    'Otillåten uppdatering när texter har levererats',
  "Pour ajouter un texte (optimisation d'un contenu existant)":
    'Att lägga till text (optimering av befintligt innehåll)',
  Gaélique: 'Gäliska',
  'La ligne de commande pour': 'Kommandoraden för',
  'Business/Management': 'Företagsekonomi/förvaltning',
  Serbe: 'Serbiska',
  "Merci d'indiquer ci-dessous vos demandes de modifications en prenant bien en compte nos conditions de repasses":
    'Ange nedan dina önskemål om ändringar, med hänsyn till våra strykningsförhållanden.',
  'rédacteurs trouvés': 'hittade författare',
  'Kirghiz ; Kirghiz': 'Kirgizisk; kirgizisk',
  Différence: 'Skillnad',
  'en cours': 'pågår.',
  'Sami du Nord': 'Norra samerna',
  Comparer: 'Jämför',
  Lituanien: 'Litauiska',
  'Typix - Mon tableau de bord': 'Typix - Min instrumentpanel',
  'Avoir au moins 8 caractères avec une lettre majuscule, un chiffre, un charactère spécial et ne pas être identique au nom':
    'Måste bestå av minst 8 tecken med en stor bokstav, en siffra eller ett specialtecken och får inte vara identiskt med namnet.',
  Webmaster: 'Webmaster',
  'Veuillez saisir des champs valides': 'Ange giltiga fält',
  Basque: 'Baskiska',
  'Exporter .csv': 'Exportera .csv',
  'Confirmer le nouveau mot de passe': 'Bekräfta det nya lösenordet',
  "Pas d'options": 'Inga alternativ',
  'Banque/Assurance': 'Bank/försäkring',
  'Vos demandes': 'Dina önskemål',
  'Coût d\u2019achat': 'Anskaffningsvärde',
  Tamil: 'Tamil',
  'Le texte a été supprimé avec succès': 'Texten har raderats',
  'Mes Commandes': 'Mina beställningar',
  Zoulou: 'Zulu',
  Afficher: 'Visa',
  Texte: 'Text',
  Islandais: 'Isländska',
  Russe: 'Ryska',
  'Tous les textes': 'Alla texter',
  Czech: 'Tjeckiska',
  'Comparaison hors ligne': 'Jämförelse offline',
  Téléphonie: 'Telefoni',
  'Valider le texte': 'Validera texten',
  'Votre text ne de doit pas dépasser 500 mots':
    'Din text bör inte överstiga 500 ord.',
  'Coquilles (double espace, « s »\u2026)': 'Skal (dubbelt mellanslag, "s"...)',
  'le consultant': 'Konsulten.',
  Coréen: 'Koreanska',
  'Nb de Silo': 'Antal silos',
  Validés: 'Validerad',
  'non attribuées': 'Ej tilldelad',
  Néerlandais: 'Nederländska',
  'Voulez-vous vraiment supprimer': 'Vill du verkligen radera',
  Rundi: 'Rundi',
  Regional: 'Regionalt',
  'Compte client': 'Kundkonto',
  Global: 'Global',
  'Tous les rôles': 'Alla roller',
  Musique: 'Musik',
  Perso: 'Personligt',
  Kinyarwanda: 'Kinyarwanda',
  'Il semblerait que tu n\u2019aies plus de crédit d\u2019analyse':
    'Det verkar som om du inte har någon mer analys att tillgå.',
  Bambara: 'Bambara',
  Sortir: 'Ut med dig',
  optionel: 'valfritt',
  'Temps restant': 'Återstående tid',
  Ukrainien: 'Ukrainska',
  'Chichewa ; Chewa ; Nyanja': 'Chichewa; Chewa; Nyanja',
  'Votre demande de révision a bien été envoyée.':
    'Din begäran om granskning har skickats.',
  'Typix - Créer rédacteur': 'Typix - Skapa en författare',
  "n'existe pas": 'finns inte.',
  Hindi: 'Hindi',
  'ne peut pas être supprimé car il est lié à des sites web':
    'kan inte raderas eftersom den är kopplad till webbplatser',
  'Commandes en cours': 'Pågående beställningar',
  'Textes livrés': 'Levererade texter',
  Validation: 'Validering',
  'Non attribuées': 'Ej tilldelad',
  'Voulez-vous vraiment désactiver': 'Vill du verkligen avaktivera',
  'Catalan ; Valencien': 'Katalanska; Valencianska',
  'Nombre de textes restants': 'Antal återstående texter',
  'Ne doit pas contenir votre nom': 'Får inte innehålla ditt namn',
  Documentation: 'Dokumentation',
  Kazakh: 'Kazakh',
  'Numéro invalide': 'Ogiltigt nummer',
  'Veuillez saisir un texte ou importer un fichier.':
    'Ange en text eller importera en fil.',
  'Nb de FAQ': 'Antal vanliga frågor',
  Analyser: 'Analysera',
  Turc: 'Turkiska',
  'Toute demande n\u2019ayant pas été précisée lors du brief':
    'Alla önskemål som inte anges i uppdraget.',
  Wallon: 'Vallonien',
  Birmane: 'Burmesiska',
  Irlandais: 'Irländska',
  Oriya: 'Oriya',
  'Réinitialiservotre mot de passe': 'Återställ ditt lösenord',
  Cuisine: 'Kök',
  'Haïtien ; créole haïtien': 'Haitisk; haitisk kreol',
  'Vous ne pouvez pas supprimer le rédacteur':
    'Du kan inte radera författareen',
  Validé: 'Validerad',
  "cet email n'existe pas": 'Detta e-postmeddelande finns inte.',
  Historique: 'Historia',
  Confirmer: 'Bekräfta',
  'à cocher si le nombre de mots est différent par texte':
    'ska kryssas i om antalet ord är olika för varje text.',
  'Fiche du site web ': 'Webbplatsblad',
  Désactiver: 'Inaktivera',
  'Travaux/BTP': 'Arbeten/byggnation',
  'La fiche du website': 'Webbplatsbladet',
  'Si le contenu ne respecte pas les règles du brief':
    'Om innehållet inte följer reglerna för uppdraget',
  Norvégien: 'Norsk',
  '0 rédacteurs': '0 författare',
  indisponible: 'otillgänglig',
  Venda: 'Venda',
  Maori: 'Maori',
  'Textes revisés': 'Reviderade texter',
  'Status texte': 'Textstatus',
  'textes livrés en attente de validation':
    'Levererade texter i väntan på validering.',
  'Typix - Mon Profile': 'Typix - Min profil',
  'le client': 'klienten',
  Romanche: 'Romanche',
  'URL du site': 'Webbplatsens URL',
  'nouvelles notifications': 'nya meddelanden',
  'Divehi ; Dhivehi ; Maldivien': 'Divehi; Dhivehi; Maldivian',
  'Êtes vous sur de vouloir sauvegarder le texte':
    'Är du säker på att du vill spara texten?',
  'Veuillez ajouter un texte initiale afin de pouvoir sauvegarder le texte en brouillon':
    'Lägg till en inledande text för att spara texten som ett utkast.',
  Xhosa: 'Xhosa',
  'Le contrat pour le client': 'Avtalet för kunden',
  rédacteurs: 'författare',
  'Ce champ est obligatoire': 'Detta fält är obligatoriskt',
  'Tarif/Mot': 'Pris/ord',
  Tadjik: 'Tadzjikiska',
  'Réf devis': 'Citat',
  'Rechercher un rédacteur': 'Sök efter en författare',
  Albanais: 'Albanska',
  Galicien: 'Galiciska',
  Disponibilité: 'Tillgänglighet',
  'Les deux mots de passe ne sonts pas identiques':
    'De två lösenorden är inte identiska',
  Tibétain: 'Tibetanska',
  'Typix - Gestion des utilisateurs': 'Typix - Användarhantering',
  'Ossétien ; Ossétique': 'Ossetisk; Ossetisk',
  Tswana: 'Tswana',
  'Ajouter un client': 'Lägg till en kund',
  'Séparez les mots-clés par un " Enter ".':
    'Separera nyckelorden med ett "Enter".',
  'Zhuang ; Chuang': 'Zhuang; Chuang',
  Thématiques: 'Teman',
  'Nb mots/texte': 'Antal ord/text',
  Commerce: 'Handel',
  'Voir les commandes': 'Se beställningarna',
  'Nombre de mots par texte': 'Antal ord per text',
  'Lien drive...': 'Länka drivenhet...',
  'Hollandais ; Flamand': 'Nederländska; flamländska',
  'Mon profil': 'Min profil',
  "Ce champs doit être sup à 0 car facturation n'est pas au réel":
    'Detta fält måste vara större än 0 eftersom faktureringen inte är verklig.',
  'Le texte a été validé avec succès': 'Texten har validerats med framgång',
  Jeux: 'Spel',
  'Voulez-vous vraiment supprimer la ligne de commande pour':
    'Vill du verkligen ta bort kommandoraden för',
  'Ndebele, Nord ; Ndebele Nord': 'Ndebele, North; Ndebele North',
  'Slave ecclésiastique ; vieux slavon ; slavon ecclésiastique ; vieux bulgare ; vieux slavon ecclésiastique':
    'Kyrkslavisk slaviska; gammal slaviska; kyrkslavisk slaviska; gammal bulgariska; gammal kyrkslavisk slaviska',
  Swahili: 'Swahili',
  Voyage: 'Resa',
  'Nombre de mot à ajouter': 'Antal ord som ska läggas till',
  'Liste des rédacteurs': 'Förteckning över författare',
  'le rédacteur': 'författareen',
  Kanuri: 'Kanuri',
  rédacteur: 'författare',
  Tatar: 'Tatariska',
  'Roumain ; Moldave ; Moldovan': 'Rumänska; moldaviska',
  'Le texte initial de': 'Den ursprungliga texten till',
  'Mail clients': 'Kundbrev',
  'Le contrat de': 'Avtalet om',
  'au moins une lettre minuscule et une lettre majuscule,un caractère spécial et un chiffre':
    'Minst en liten och en stor bokstav, ett specialtecken och ett nummer.',
  Transport: 'Transport',
  'Nombre total de mots (existants et ajoutés) :':
    'Totalt antal ord (befintliga och tillagda) :',
  'Rechercher par mail, prénom, nom, site...':
    'Sök efter e-post, förnamn, efternamn, webbplats...',
  'Nombre de mot à ajouter (Min/Max) : ':
    'Antal ord som ska läggas till (Min/Max) :',
  Compte: 'Konto',
  'Tourisme/Loisirs': 'Turism/fritid',
  'Nouveau texte': 'Ny text',
  Max: 'Max',
  'Kalaallisut ; Groenlandais': 'Kalaallisut; grönländska',
  'Oups...!il y a une/plusieurs erreur(s) dans la fenêtre du gap sémantique. Veuillez vérifier son contenu pour la/les corriger et soumettre à nouveau votre texte.':
    'Oops...! Det finns ett eller flera fel i fönstret för den semantiska luckan. Kontrollera innehållet för att korrigera det eller dem och skicka in din text på nytt.',
  'ne peut pas être supprimé pour le moment': 'kan inte raderas för tillfället',
  'Le texte': 'Texten',
  URL: 'URL',
  "Un utilisateur existe déjà avec l'email":
    'Det finns redan en användare med e-postadressen',
  Cree: 'Cree',
  Prénom: 'Förnamn',
  'commandes prête': 'beställningar klara',
  Réinitialiser: 'Återställ',
  'Veuillez fournir une date valide': 'Ange ett giltigt datum',
  'Kuanyama ; Kwanyama': 'Kuanyama; Kwanyama',
  'Luxembourgeois ; Letzeburgesch': 'Luxemburgiska; Letzeburgesch',
  Suédois: 'Svenska',
  'Confirmer le mot de passe': 'Bekräfta lösenordet',
  'Ce champs est obligatoire et ne peut avoir que des nombres':
    'Detta fält är obligatoriskt och kan endast innehålla siffror.',
  Sciences: 'Vetenskap',
  Tchétchène: 'Tjetjenien',
  "est le seul client pour un site, il faut ajouter d'autres pour pouvoir le supprimer":
    'är den enda klienten för en webbplats, måste andra läggas till för att ta bort den.',
  'Le mot de passe de confirmation ne correspond pas':
    'Bekräftelselösenordet matchar inte',
  Swati: 'Swati',
  Danois: 'Danska',
  Sauvegarder: 'Spara',
  'Veuillez changer les informations suivantes titre et/ou mot clé':
    'Vänligen ändra följande information: titel och/eller nyckelord.',
  'importer un document': 'importera ett dokument',
  Maltais: 'Maltesiska',
  'Nb de mots': 'Antal ord',
  'Les données fournies sont invalides': 'Uppgifterna är ogiltiga.',
  'Livraison estimée': 'Beräknad leverans',
  'Grec moderne (1453-)': 'Nygrekiska (1453-)',
  Avestan: 'Avestan',
  Inuktitut: 'Inuktitut',
  'Nombre total de mots ajoutés': 'Totalt antal ord som lagts till',
  'le contenu présente des inexactitudes':
    'Innehållet innehåller felaktigheter.',
  Finnois: 'Finska',
  'Ajouter un admin': 'Lägg till en administratör',
  'Min/Max': 'Min/Max',
  'Modifier le consultant': 'Ändra konsulten',
  'Liste des clients': 'Förteckning över kunder',
  'Nom client': 'Kundnamn',
  Vert: 'Grön',
  'Mots clés manquants': 'Saknade nyckelord',
  Lao: 'Lao',
  Somali: 'Somaliska',
  'Nb de silo par texte': 'Antal silos per text',
  Italien: 'Italienska',
  'La commande a été mis à jour avec succès !':
    'Beställningen har uppdaterats framgångsrikt!',
  Bosniaque: 'Bosniska',
  'Thématiques d\u2019expertise': 'Teman för sakkunskap',
  'Ce mot de passe est le mot de passe que vous avez oublié':
    'Det här är lösenordet som du har glömt',
  'a été désactivé avec succès': 'har avaktiverats med framgång.',
  Kongo: 'Kongo',
  'Postuler à la commande': 'Ansökan om beställning',
  'mot-clé': 'nyckelord',
  Abkhazien: 'Abchaziska',
  Mongolien: 'Mongoliska',
  'Typix - Fiche site web': 'Typix - Webbplats',
  'Mail envoyé': 'Avsändning av post',
  Géorgien: 'Georgiska',
  'Si des phrases ne sont pas dans un français correct':
    'Om meningarna inte är på korrekt franska',
  'En cours': 'På gång',
  voir: 'se',
  "Date d'ajout": 'Datum som läggs till',
  'Tarif au mot': 'Pris per ord',
  Thai: 'Thai',
  'Détails du texte': 'Uppgifter om texten',
  'Commandes en retard': 'Sena beställningar',
  disponible: 'tillgänglig',
  Droit: 'Lag',
  Azerbaïdjanais: 'Azerbajdzjanska',
  'Ajouter un consultant': 'Lägg till en konsult',
  'Dans le cadre d\u2019une repasse sur des contenus, seuls les éléments suivants seront pris en charge':
    'När innehåll spelas upp på nytt stöds endast följande objekt',
  'doit comprendre au moins 8 charactères': 'måste innehålla minst 8 tecken',
  'Titre de la commande': 'Beställningens titel',
  'Langues bihari': 'Bihari-språken',
  'Livraison estimé au': 'Beräknad leverans senast',
  "Le nombre de texte de la commande ne peut pas être inférieur aux nombre de textes attribuées au(x) rédacteur(s). Veuillez d'abord modifier les informations de la ligne de commande":
    'Antalet texter i beställningen får inte vara mindre än det antal texter som tilldelats författaren/författarna. Ändra först informationen i kommandoraden',
  'Date de livraison': 'Leveransdatum',
  'Voulez-vous vraiment supprimer le contrat de': 'Vill ni verkligen avskaffa',
  'Facturation au réel': 'Faktisk fakturering',
  'Gap sémantique': 'Semantisk klyfta',
  Estonien: 'Estniska',
  Wolof: 'Wolof',
  'Coût d\u2019achat max/mot': 'Högsta inköpskostnad/ord',
  'Détail de la commande': 'Beställningsuppgifter',
  'Export en cours de téléchargement\u2026':
    'Exporten håller på att laddas upp...',
  'Ce champ est obligatoire et doit être inférieur ou égal au Nombre total des textesde la commande':
    'Detta fält är obligatoriskt och måste vara mindre än eller lika med det totala antalet texter i beställningen.',
  Arabe: 'Arabiska',
  Bislama: 'Bislama',
  'Mail client': 'Kundbrev',
  '(Titre, mot clé, url, balise, méta-description)':
    '(Titel, nyckelord, url, tagg, meta-beskrivning)',
  'Typix - Nouvelle commande': 'Typix - Ny beställning',
  'Révision en attente': 'Översyn pågår.',
  'Le consultant': 'Konsulten',
  Entreprise: 'Företag',
  Aragonais: 'Aragonien',
  Kashmiri: 'Kashmiri',
  'a été activé': 'har aktiverats.',
  'Interlingua (Association internationale des langues auxiliaires)':
    'Interlingua (Internationella sammanslutningen för hjälpspråk)',
  Actualités: 'Nyheter',
  'Votre décision a été prise en compte avec succès':
    'Ditt beslut har beaktats med framgång',
  Afrikaans: 'Afrikaans',
  'contenu en attente de vérification client':
    'innehåll som väntar på kundens verifiering',
  'Fiche du rédacteur': 'författareens fil',
  Outillage: 'Verktyg',
  'Typix - Détails de la commande': 'Typix - Beställningsuppgifter',
  'Ajouter ou compléter ci-dessous votre contenu :':
    'Lägg till eller komplettera ditt innehåll nedan:',
  Ewe: 'Ewe',
  'contenu en attente de repasse rédacteur':
    'innehåll i väntan på återspelning',
  'La commande a été supprimé avec succès': 'Beställningen har tagits bort.',
  'Expressions ou phrases françaises mais ne vous convenant pas':
    'Franska uttryck eller fraser som inte passar dig',
  Corse: 'Korsika',
  Modifier: 'Redigera',
  'Nb de GMB': 'Antal GMB:er',
  Oromo: 'Oromo',
  Polish: 'Polska',
  'Insérez votre gap sémantique avec l’occurrence':
    'Lägg in din semantiska lucka med förekomsten',
  'Nb de textes à optimiser': 'Antal texter som ska optimeras',
  Mode: 'Läge',
  Akan: 'Akan',
  'Révisions effectués': 'Revideringar som gjorts',
  'Modifier l\u2019admin': 'Redigera administratören',
  'Télécharger le document': 'Ladda ner dokumentet',
  Hausa: 'Hausa',
  'Notes rédacteurs': 'författarenas anteckningar',
  Quechua: 'Quechua',
  Féroïen: 'Färöiska',
  'Modifier le rédacteur': 'Ändra författare',
  Mail: 'E-post',
  Rédacteurs: 'Författare',
  'Typix - Détails du texte': 'Typix - Textinformation',
  Hongrois: 'Ungerska',
  'Bon pour livraison': 'Bra för leverans',
  'Au réel': 'I den verkliga världen',
  'Hiri Motu': 'Hiri Motu',
  Hébreu: 'Hebreiska',
  'Nombre total de mots ajoutés :': 'Totalt antal ord som lagts till :',
  Komi: 'Komi',
  'Doit être inférieur au nombre maximum':
    'Måste vara mindre än det maximala antalet',
  'Nom du texte': 'Textens namn',
  'Ajouter un rédacteur': 'Lägg till en författare',
  Ou: 'Eller',
  Utilisateurs: 'Användare',
  Heures: 'Timmar',
  'Santé/Bien-être': 'Hälsa/välbefinnande',
  'Interlingue ; Occidental': 'Interlingual; västerländsk',
  'Luba-Katanga': 'Luba-Katanga',
  Letton: 'Lettiska',
  Anglais: 'Engelska',
  Envoyer: 'Skicka',
  'Tableau de bord': 'Instrumentpanel',
  'Demande de révision': 'Begäran om översyn',
  'Résultat sémantique': 'Semantiskt resultat',
  'Chercher un rédacteur': 'Sök efter en författare',
  'Ce champs est obligatoire': 'Detta fält är obligatoriskt',
  Rédaction: 'Redaktionell',
  'Modifier le client': 'Ändra klienten',
  'Voulez-vous vraiment supprimer le rédacteur':
    'Vill du verkligen ta bort redigeraren?',
  Titre: 'Titel',
  Langue: 'Språk',
  'Mot clé': 'Nyckelord',
  'Les demandes suivantes ne seront pas prises en compte':
    'Följande ansökningar kommer inte att beaktas',
  'délai dépassé': 'missad tidsfrist',
  Tchouvache: 'Tchouvache',
  Ganda: 'Ganda',
  Twi: 'Twi',
  'Contrat déjà existant, veuillez modifier les informations':
    'Befintligt avtal, vänligen ändra informationen',
  Igbo: 'Igbo',
  Yiddish: 'Jiddisch',
  'Limburgan ; Limburger ; Limburgish': 'Limburgan; Limburger; Limburgish',
  'Commandes validées': 'Validerade beställningar',
  'listeRedacteur-': 'listRedactor-',
  Étape: 'Steg',
  'Energie/Environnement': 'Energi/miljö',
  Min: 'Min',
  Gujarati: 'Gujarati',
  'ne doit pas inclure votre nom/prénom':
    'får inte innehålla ditt namn/förnamn',
  'Typix - Fiche rédacteur': 'Typix - Skribentens fil',
  'révisions effectuées en attentes de validation':
    'ändringar som gjorts i väntan på validering',
  'Textes validés': 'Validerade texter',
  Art: 'Konst',
  Dzongkha: 'Dzongkha',
  Polyvalent: 'Mångsidig',
  Sundanese: 'Sundanesiska',
  'Nb total de textes': 'Totalt antal texter',
  'Nombre de mots gérés': 'Antal ord som förvaltas',
  "L'émail": 'Emalj',
  'Afficher les résultats': 'Visa resultat',
  'Frison occidental': 'Västfrisiska',
  'Modification effectuée avec succès': 'Ändringen har slutförts med framgång',
  'Balise titre': 'Titelmärkning',
  'Typix - Mon profil': 'Typix - Min profil',
  'email invalide': 'ogiltig e-post',
  'Veuillez ajouter une url afin de pouvoir sauvegarder le texte en brouillon':
    'Lägg till en url så att du kan spara texten som ett utkast.',
  Macédonien: 'Makedonska',
  'Votre candidature a bien été envoyée': 'Din ansökan har skickats',
  'E-mail': 'E-post',
  Fulah: 'Fulah',
  'Ajouter ou compléter ci-dessous votre contenu':
    'Lägg till eller komplettera ditt innehåll nedan',
  Manx: 'Manx',
  'Ajout de mots-clés avec la fréquence restante':
    'Lägga till nyckelord med återstående frekvens',
  'Fiche du site web': 'Webbplatsblad',
  Contacter: 'Kontakta',
  Fidjien: 'Fijian',
  Déconnexion: 'Frånkoppling',
  email: 'E-post',
  'Nombre de textes': 'Antal texter',
  'Enregistrer et informer client': 'Registrera och informera kunderna',
  'Date de validation': 'Datum för validering',
  'Tonga (Îles Tonga)': 'Tonga (Tongaöarna)',
  Javanais: 'Javanska',
  Portugais: 'Portugisiska',
  Biélorusse: 'Vitryska',
  'Pour ajouter un nouveau texte': 'Lägg till en ny text',
  'Enfant/Puéri': 'Barn/Puperium',
  'Mots clés': 'Nyckelord',
  Emploi: 'Sysselsättning',
  'Voulez-vous vraiment supprimer la commande de': 'Vill du verkligen ta bort',
  Consultants: 'Konsulter',
  Volapük: 'Volapük',
  Services: 'Tjänster',
  'mot-clé (fréquence)': 'nyckelord (frekvens)',
  'Ne plus afficher ce message': 'Visa inte det här meddelandet igen.',
  Consultant: 'Konsult',
  "Une erreur s'est produite": 'Ett fel har inträffat',
  'Avoir au moins 8 caractères avec une lettre majuscule, un chiffre, un charactère spécial':
    'Minst 8 tecken med en stor bokstav, en siffra, ett specialtecken.',
  'Pushto ; Pashto': 'Pushto; Pashto',
  Français: 'Franska',
  validées: 'validerad',
  'Url du site': 'Webbplatsens URL',
  Précédent: 'Föregående',
  'car celui-ci a déjà rédigé des textes':
    'eftersom den senare redan har utarbetat texter.',
  'Kikuyu ; Gikuyu': 'Kikuyu; Gikuyu',
  Brouillons: 'Utkast',
  Ido: 'Ido',
  'Contenu disponible': 'Tillgängligt innehåll',
  Enseignement: 'Undervisning',
  'a été livré avec succès': 'har levererats med framgång.',
  Chinois: 'Kinesiska',
  Vietnamien: 'Vietnamesiska',
  'Nouveau client': 'Ny kund',
  'Votre profil a été mis à jour avec succès.': 'Din profil har uppdaterats.',
  Consignes: 'Instruktioner',
  'Mot de passe': 'Lösenord',
  Annuler: 'Avbryt',
  Non: 'Ingen',
  Nom: 'Namn',
  Guarani: 'Guarani',
  "il y'a des commandes liés à au site web":
    'Det finns beställningar som rör webbplatsen.',
  Score: 'Resultat',
  'Mot de passe mis à jour avec succès': 'Lösenordet har uppdaterats',
  Latin: 'Latin',
  Technologie: 'Teknik',
  Internet: 'Internet',
  Nauru: 'Nauru',
  'a été créé avec succès': 'har skapats med framgång.',
  Roumain: 'Rumänska',
  Assamais: 'Assamese',
  'Khmer central': 'Centrala khmerer',
  'Navajo ; Navaho': 'Navajo; Navaho',
  'Panjabi ; Punjabi': 'Punjabi; Punjabi',
  'Analyse/Limite': 'Analys/gräns',
  Grec: 'Grekiska',
  Ojibwa: 'Ojibwa',
  'Mes Projets': 'Mina projekt',
  'keyword1 (2)\nkeyword2 (1)\nkeyword3 (1)\nkeyword4 (2)\n\u2026':
    'nyckelord1 (2)\nnyckelord2 (1)\nnyckelord3 (1)\nnyckelord4 (2)\n...',
  'ajouter un texte': 'lägga till en text',
  'Détail de la prestation': 'Uppgifter om tjänsten',
  "Vous avez la possibilité d'exporter le rapport d'une, ou de plusieurs commandes. Pour exporter toutes les commandes d'un client, il vous suffit juste de cliquer sur l'icône de téléchargement en jaune":
    'Du har möjlighet att exportera rapporten för en eller flera beställningar. För att exportera alla beställningar för en kund behöver du bara klicka på den gula nedladdningsikonen.',
  'Gestion des utilisateurs': 'Hantering av användare',
  'livré et inférieur au nombre de texte non affecté':
    'som levererats och som är mindre än antalet icke tilldelade texter.',
  'Date deadline validation client': 'Sista dag för kundvalidering',
  Aymara: 'Aymara',
  Validées: 'Validerad',
  Notes: 'Anteckningar',
  'Nombre de GMB': 'Antal GMB:er',
  'résultats sur': 'resultat på',
  'Prénom / Nom du client': 'Förnamn / kundens namn',
  'Tout télécharger': 'Ladda ner alla',
  'Champs obligatoires': 'Obligatoriska fält',
  Éditer: 'Redigera',
  'Coller le texte initial dans le champs ci-dessous':
    'Klistra in originaltexten i fältet nedan',
  'ID rédacteur': 'författares-ID',
  Arménien: 'Armeniska',
  'Grande distribution': 'Storskalig distribution',
  Welsh: 'Walesiska',
  Herero: 'Herero',
  'Enrichissement sémantique': 'Semantisk berikning',
  'Détail de la prestation du contenu': 'Uppgifter om hur innehållet levereras',
  Noir: 'Svart',
  'Ajouter un texte': 'Lägg till en text',
  Traduction: 'Översättning',
  'Immo/Defisc': 'Egendom/skatt',
  'Brief détaillé': 'Detaljerad information',
  'Séparez les mots-clés par un Enter .':
    'Separera nyckelorden med ett Enter .',
  Prix: 'Utmärkelser',
  'mot de passe oublié': 'Glömt lösenord',
  'Ndebele, South ; South Ndebele': 'Ndebele, South; South Ndebele',
  'Demander une révision': 'Begär en recension',
  'Demander une repasse globale': 'Begär en omfattande översy',
  'Sotho, Southern': 'Sotho, södra Sotho',
  'Le titre de la commande existe déjà': 'Beställningens titel finns redan',
  'Félicitations !': 'Grattis!',
  Kurde: 'Kurdiska',
  Adresse: 'Adress',
  Espagnol: 'Spanska',
  Ndonga: 'Ndonga',
  'En retard': 'Sen',
  'a été ajouté avec succès': 'har lagts till med framgång.',
  Commandes: 'beställningar',
  'a été mis en brouillon': 'har utarbetats.',
  'Rédacteur attribué': 'Tilldelad författare',
  Rechercher: 'Sök på',
  'Nouvelle commande': 'Ny beställning',
  'Commandes non attribuées': 'Icke tilldelade beställningar',
  'Le texte a été mis en brouillon': 'Texten har utarbetats',
  'Contact edito': 'Kontakta edito',
  Langues: 'Språk',
  Orange: 'Orange',
  'Nynorsk norvégien': 'Norska Nynorsk',
  Shona: 'Shona',
  Turkmène: 'Turkmeniska',
  'contenu repassé, en attente validation client':
    'strykt innehåll, i väntan på kundens validering',
  'Nombre de mots rédigés': 'Antal skrivna ord',
  Malay: 'Malajiska',
  'Nombre de Silo': 'Antal silos',
  Oui: 'Ja',
  Automobile: 'Fordonsindustrin',
  Résultats: 'Resultat',
  Afar: 'Afar',
  'Tâche - id tâche': 'Uppgift - uppgiftens id',
  'Ce champ est obligatoire doit être supérieur à 0':
    'Detta fält är obligatoriskt och måste vara större än 0.',
  'Vous êtes sûr': 'Är du säker på att',
  'Réinitialiser votre mot de passe': 'Återställ ditt lösenord',
  Sindhi: 'Sindhi',
  Bengali: 'Bengali',
  'Bokmål, norvégien ; Bokmål norvégien': 'Bokmål, norska; norska Bokmål',
  'Nombre de Jours': 'Antal dagar',
  'Min/Max mots': 'Min/Max antal ord',
  Slovène: 'Slovenska',
  Site: 'Webbplats',
  'Sinhala ; Cingalais': 'Singalesiska; singalesiska',
  Bulgare: 'Bulgariska',
  'Liste des commandes': 'Förteckning över beställningar',
  "L'utilisateur": 'Användaren',
  Uzbek: 'Uzbekiska',
  'Titre du texte': 'Textens titel',
  Postuler: 'Ansök på',
  'Nb de textes par Silo': 'Antal texter per silo',
  'Nombre total de mots (existants et ajoutés)':
    'Totalt antal ord (befintliga och tillagda)',
  'Max.': 'Max.',
  Beauté: 'Skönhet',
  Humanitaire: 'Humanitärt bistånd',
  Telugu: 'Telugu',
  'Recherche par rédacteur ou texte': 'Sök efter författare eller text',
  'Balise meta-description': 'Metabeskrivningstaggen',
  Malgache: 'Malagasy',
  Tagalog: 'Tagalog',
  'Ce champs doit être sup à 0 et inférieur à 1':
    'Detta fält måste vara större än 0 och mindre än 1.',
  'Le rédacteur': 'författareen',
  'a été mis à jour avec succès': 'har uppdaterats med framgång.',
  'Avoir au moins 8 caractères avec une lettre majuscule, un chiffre, un symbole et ne pas être identique au nom.':
    'Måste bestå av minst 8 tecken med en stor bokstav, en siffra, en symbol och får inte vara identisk med namnet.',
  'textes en plus': 'fler texter',
  'Format souhaité': 'Önskat format',
  Chamorro: 'Chamorro',
  Malayalam: 'Malayalam',
  'Détail ligne de commande': 'Uppgifter om beställningslinjen',
  'Veuillez préciser la date': 'Ange datum',
  Pali: 'Pali',
  Clients: 'Kunder',
  'Total facturé': 'Totalt fakturerat belopp',
  'contenu validé par le client': 'innehåll som validerats av kunden',
  'Occitan (post 1500)': 'Occitanska (efter 1500)',
  Japonais: 'Japanska',
  Avaric: 'Avaric',
  'Voulez-vous vraiment activer': 'Vill du verkligen aktivera',
  Sanskrit: 'Sanskrit',
  'Toutes les notifications': 'Alla meddelanden',
  Marathi: 'Marathi',
  Persan: 'Persisk',
  'La commande pour le client': 'Beställningen för kunden',
  'Intitulé de la commande': 'Beställningens titel',
  Samoan: 'Samoanska',
  Autres: 'Övriga',
  Sport: 'Sport',
  "il y'a des commandes liés au site web ":
    'Det finns beställningar som rör webbplatsen.',
  'Ajout de mots-clé avec la fréquence restante':
    'Lägga till nyckelord med återstående frekvens',
  'a été supprimé avec succès': 'har avlägsnats med framgång.',
  Postulé: '',
  Literie: 'Sängkläder',
  'Édité par le client': 'Redigerad av kunden',
  Cornouailles: 'Cornwall',
  Tigrinya: 'Tigrinya',
  Textes: 'Texter',
  'Soumettre un nouveau texte': 'Skicka in en ny text',
  Téléphone: 'Telefon',
  Deadline: 'Sista ansökningsdag',
  "Une erreur s'est produite. Veuillez vérifier votre saisie ou essayer plus tard.":
    'Ett fel har inträffat. Kontrollera din post eller försök igen senare.',
  Marshallais: 'Marshall',
  "La demande d'ajout de crédit est envoyée":
    'Begäran om att lägga till krediter skickas',
  'Le rédacteur ': 'författareen',
  'Demander des crédits': 'Ansökan om kredit',
  "Cette commande n'existe pas": 'Detta kommando finns inte',
  Inupiaq: 'Inupiaq',
  Tahitien: 'Tahiti',
  Admins: 'Administratörer',
  'Mettez des mots-clés avec fréquence': 'Ange nyckelord med frekvens',
  Notifications: 'Meddelanden',
  'en retard': 'sent',
  "l'admin": 'administratören',
  'Mail rédacteurs': 'E-postförfattare',
  Amharique: 'Amhariska',
  Societe: 'Företag',
  Tsonga: 'Tsonga',
  'Nombre de textes ajoutés': 'Antal texter som lagts till',
  Société: 'Företag',
  Yoruba: 'Yoruba',
  'Le texte a été livré avec succès': 'Texten har levererats med framgång.',
  'Mettez votre texte': 'Sätt in din text',
  "Oups....! Une erreur s'est produite": 'Oops....! Ett fel har inträffat',
  'Prénom/Nom': 'Förnamn/efternamn',
  'Max doit être sup à Min': 'Max måste vara större än Min',
  'Importer un autre document': 'Importera ett annat dokument',
  'Votre demande a expirée .. veuillez refaire une autre':
    'Din ansökan har löpt ut ... gör en ny ansökan.',
  'Veuillez corriger le format de votre gap sémantique afin de pouvoir sauvegarder le texte en brouillon':
    'Korrigera formatet på din semantiska lucka för att spara texten som ett utkast.',
  Exemple: 'Exempel',
  Slovaque: 'Slovakiska',
  Finance: 'Ekonomi',
  Réactiver: 'Återaktivera',
  'Nb textes': 'Antal texter',
  'Intitulé commande': 'Titel på beställningen',
  Supprimer: 'Ta bort',
  Brouillon: 'Utkast',
  'Mots clés restants': 'Återstående nyckelord',
  Date: 'Datum',
  Rôle: 'Roll',
  'Ce champs doit être sup à 0': 'Detta fält måste vara större än 0.',
  'Maison/Déco': 'Hem/Inredning',
  'Vous avez': 'Du har',
  'Cette ligne de commande ne peut pas être supprimée car une partie de ses textes a été livré':
    'Denna kommandorad kan inte raderas eftersom en del av texten har levererats.',
  'vérifiez votre email et cliquez sur le lien pour continuer !':
    'kontrollera ditt e-postmeddelande och klicka på länken för att fortsätta!',
  Sarde: 'Sardinien',
  'Note rédacteur': 'författareens anmärkning',
  'Uighur ; Uyghur': 'Uiguriska; uiguriska',
  Name: 'Namn',
  'Commandes intelligentes': 'Smart kommandon',
  'Commandes avec révision': 'Smart + review kommandon',
  'Type de contenu': 'Innehållstyp',
  'Fiche produit': 'Produktblad',
  GMB: 'GMB',
  'STORE LOCATOR': 'STORE LOCATOR',
  'Rédaction premium': 'Premiumredigering',
  'Rédaction avec révision': 'Smart + review redigering',
  'Rédaction intelligente': 'Smart redigering',
  PREMIUM: 'PREMIUM',
  Type: 'Typ',
  Editer: 'Redigera',
  'Tous les types': 'Alla typer',
  Smart: 'Smart',
  'Smart + review': 'Smart + review',
  'Factures traitées': 'Fakturor som behandlats',
  'Factures à valider': 'Fakturor att validera',
  'Impossible de générer la facture ! Veuillez réessayer':
    'Det går inte att generera fakturan! Försök igen',
  'Récapitulatif de facturation': 'Faktureringsöversikt',
  'Demander une modification': 'Be om ändring',
  'Valider et envoyer la facture': 'Validera och skicka fakturan',
  'Demande de modification': 'Ändringsbegäran',
  'La facture a été validé avec succès!':
    'Fakturan har validerats framgångsrikt!',
  "Oups un erreur c'est produit!": 'Hoppsan, ett fel inträffade!',
  'Nombre de texte': 'Antal texter',
  'Nombre de mot par texte :': 'Antal ord per text:',
  Paiement: 'Betalning',
  Virement: 'Banköverföring',
  Délai: 'Tidsgräns',
  'Référence de votre facture': 'Din fakturreferens',
  'Coût au mot': 'Kostnad per ord',
  'Montant global de la commande': 'Totalbeloppet för beställningen',
  'Votre facture': 'Din faktura',
  'Importer votre facture': 'Importera din faktura',
  'Pas de fichier disponible': 'Ingen tillgänglig fil',
  Télécharger: 'Ladda ner',
  Relancer: 'Skicka igen',
  'Générer la facture': 'Generera fakturan',
  Payée: 'Betald',
  'En cours de paiement': 'Pågående betalning',
  'En cours de validation': 'Pågående validering',
  'A envoyer': 'Att skickas',
  'Modification demandée': 'Begärd ändring',
  'Référence de facture est obligatoire': 'Fakturreferens är obligatoriskt',
  'Le coût au mot doit être un nombre':
    'Kostnaden per ord måste vara ett nummer',
  'Le coût au mot doit être un nombre positif':
    'Kostnaden per ord måste vara ett positivt nummer',
  'Le coût au mot est obligatoire': 'Kostnaden per ord är obligatorisk',
  'Le montant global de la commande doit être un nombre':
    'Totalbeloppet för beställningen måste vara ett nummer',
  'Le montant global de la commande doit être un nombre positif':
    'Totalbeloppet för beställningen måste vara ett positivt nummer',
  'Le montant global de la commande est obligatoire':
    'Totalbeloppet för beställningen är obligatoriskt',
  'Bill reference existe déja , veuillez réessayer':
    'Fakturreferensen finns redan, försök igen',
  'La facture a été envoyée avec succès!':
    'Fakturan har skickats framgångsrikt!',

  'Montant estimé': 'Uppskattat belopp',
  Action: 'Åtgärd',
  Facture: 'Faktura',
  Factures: 'Fakturor',
  Statut: 'Status',
  'Valider la facture': 'Validera fakturan',
  'La relance a été envoyée avec succès !':
    'Påminnelsen har skickats framgångsrikt!',
  'La demande de modification a été envoyée avec succès !':
    'Ändringsbegäran har skickats framgångsrikt!',
  'Le cout est trop élevé': 'Kostnaden är för hög',
  'Le montant global de la commande est trop élevé ':
    'Det totala beloppet för beställningen är för högt',
  jours: 'dagar',
  'Factures à envoyer': 'Fakturor att skicka',
  'Valider le paiement': 'Validera betalning',
  'Générer une facture': 'Generera en faktura',
  'Montant rédacteur': 'Författarbelopp',
  'Montant estimé par Typix': 'Beräknat belopp av Typix',
  'Voir la modification': 'Visa ändringen',
  'Tag de Titre': 'Titel-tag',
  'Meta description': 'Meta-beskrivning',
  'Nom du produit': 'Produktnamn',
  Avantages: 'Fördelar',
  'Description détaillée': 'Detaljerad beskrivning',
  'En cours de modification': 'Under ändring',
  'Mes Factures': 'Mina fakturor',
  'En attente de validation': 'Väntar på validering',
  'les valeurs négatives ne sont pas autorisées':
    'Negativa värden är inte tillåtna.',
  'Texte Seulement': 'Endast text',
  'Traduction Seulement': 'Endast översättning',
  'La relance peut être faite 20 jours après la soumissions de la facture':
    'Påminnelsen kan skickas 20 dagar efter fakturainskick',
  'Verifier la facture': 'Kontrollera fakturan',
  Instructions: 'Instruktioner',
  'Votre avis pour la commande': 'Din åsikt om beställningen',
  'Votre évaluation est précieuse pour nous ! Merci !':
    'Din utvärdering är värdefull för oss! Tack!',
  "Une erreur s'est produite lors de la soumission de votre évaluation. Veuillez réessayer ultérieurement!":
    'Ett fel inträffade vid inskickandet av din utvärdering. Försök igen senare!',
  Evaluer: 'Utvärdera',
  Commentaire: 'Kommentar',
  'Votre commantaire': 'Din kommentar',
  Note: 'Betyg',
  'Votre retour est précieux pour nous et nous aide à améliorer nos services.':
    'Din feedback är värdefull för oss och hjälper oss att förbättra våra tjänster.',

  ' Votre retour est précieux pour nous et nous aide à améliorer nos services. Merci de partager votre évaluation avec nous!':
    'Din feedback är värdefull för oss och hjälper oss att förbättra våra tjänster. Tack för att du delar din utvärdering med oss!',
  'Notes rédacteur': 'Författarens anteckningar',
  'Avis client': 'Kundrecensioner',
  'Pas de commantaire': 'Ingen kommentar',
  "Qu'avez vous pensé de votre commande?": 'Vad tyckte du om din beställning?',
  'Evaluation de la commande': 'Utvärdering av beställningen',
  'Rédiger un commentaire': 'Skriv en kommentar',
  'Contribuez à nous permettre de vous proposer les services de rédaction de la plus haute qualité en partageant vos commentaires.':
    'Bidra till att vi kan erbjuda dig tjänster av högsta kvalitet genom att dela dina kommentarer.',
  'Que pouvons-nous améliorer?': 'Vad kan vi förbättra?',
  Continuer: 'Fortsätt',
  'Le dernier texte a été validé avec succès':
    'Den senaste texten godkändes med framgång',
  'Rechercher par rédacteur, commandes, et montant':
    'Sök efter författare, beställningar och belopp',
  obligatoire: 'obligatorisk',
  'Note admin': 'Administratörsanteckning',
  'Note client': 'Kundanteckning',
  Partie: 'Del',
  'Vous venez de valider tous les contenus rédigés par ':
    'Du har precis godkänt allt innehåll som skrivits av ',
  "Qu'en avez vous pensé": 'Vad tycker du om det',
  'La facture a été générée avec succès !':
    'Fakturan har genererats framgångsrikt!',
  'La taille du titre et/ou la méta-description est trop courte':
    'Längden på titeln och/eller meta-beskrivningen är för kort',
  'Demande de révision globale': 'Kräva en global översyn',
  Valider: 'Validera',
  'Obligatoire en cas d’optimisation ou traduction':
    'Obligatoriskt vid optimering eller översättning',
  'Mots clés Bourrées': 'Keyword stuffing',
  'Votre commentaire...': 'Din kommentar...',
  'Ex : "Contenus de très bonne qualité':
    'Ex: "Mycket bra kvalitet på innehållet"',
  "pouvez-vous s'il vous plaît reprendre ce/tte rédacteur/rice pour les prochaines commandes?":
    'Kan du snälla tilldela denna skribent för framtida beställningar?',
  'Ce bouton est pour informer le client que le texte initial, contenait déjà du keyword stuffing':
    'Denna knapp är för att informera kunden om att den ursprungliga texten redan innehöll sökordsfyllning',
  'Maillage Interne': 'Internal link',
  'Modifier le texte': 'Redigera texten',
  Ajouter: 'Lägg till',
  'Demande examen': 'Begäran om examination',
  'Recherche de mots clés à haute densité': 'Keyword stuffing forskning',
  'Oups, il semblerait qu’il y ait du keyword stuffing dans votre texte : ':
    'Oops, det verkar finnas keyword stuffing i din text: ',
  'Oups, il semblerait qu’il y ait du keyword stuffing (trop d’occurrences d’un ou plusieurs mots clés) dans le texte initial.':
    'Oops, det verkar finnas keyword stuffing (för många förekomster av ett eller flera nyckelord) i den ursprungliga texten.',
  'Merci de vous assurer que la densité des mots clés soit optimisée pour le SEO (une occurrence maximum tous les 50 mots).':
    'Var vänlig och se till att nyckelordstätheten är optimerad för SEO (högst en förekomst var 50:e ord).',
  'Pour cela merci de ne pas retoucher le texte existant et de bien vouloir envoyer une demande d’examen au support.':
    'För detta, snälla redigera inte den befintliga texten och skicka gärna en förfrågan om granskning till support.',
  'Votre demande a été bien envoyée !':
    'Din förfrågan har skickats framgångsrikt!',
  "Il y a eu un problème lors de l'envoi de votre demande":
    'Det uppstod ett problem vid sändningen av din förfrågan.',
  'Veuillez attendez pour le  keyword Stuffing analyse':
    'Vänta på analysen av keyword stuffing.',
  'Inserez le maillage interne': 'Infoga den interna nätverket',
  'Merci de vous assurer que la densité des mots clés soit optimisée pour le SEO (une occurrence maximum tous les 50 mots).\nSi vous constatez un terme pouvant avoir plusieurs significations (Exemple : Former / Formation/ Forme ou Matelas/Matelassé) , vous pouvez faire une demande d’examen auprès du support.':
    'Var vänlig och se till att sökordsdensiteten är optimerad för SEO (maximalt en förekomst var 50:e ord).\nOm du stöter på en term som kan ha flera betydelser (Exempel: Former / Formation / Form eller Matelas / Matelassé), kan du begära en granskning från supporten.',
  'Mots clés à haute densité dans le texte actuel':
    'Palabras clave de alta densidad en el texto actual',
  'Mots clés à haute densidad dans le texte initial':
    'Palabras clave de alta densidad en el texto inicial',
  'Texte en cours de rédaction !': 'Text under redigering !',
  'Êtes-vous sûr(e) de vouloir valider le texte ? Assurez-vous d’abord d’avoir enregistré les modifications.':
    'Är du säker på att du vill bekräfta texten? Se först till att du har sparat ändringarna.',
  "Information dernière minute ! Ton stock de crédits sera bientôt épuisé :( Pour anticiper, n'hésite pas à demander dès maintenant plus de crédits pour terminer ta commande.":
    'Sista minuten-information! Ditt kreditsaldo kommer snart att vara tomt :( För att vara i förväg, tveka inte att begära mer krediter nu för att slutföra din beställning.',
  'Voulez-vous confirmer l’URL actuelle ou la modifier ?':
    'Vill du bekräfta den aktuella webbadressen eller ändra den?',
  'Veuillez fournir une URL valide': 'Var god ange en giltig webbadress.',
  'Merci de bien vouloir indiquer ci-dessous l’url de la page où a été intégré le contenu:':
    'Vänligen ange URL:en till sidan där innehållet har integrerats nedan:',
  'Contenu en ligne': 'Onlineinnehåll',
  'Télécharger le document de révision': 'Ladda ner revisionsdokumentet',
  'Date d’envoi': 'Sändningsdatum',
  Information: 'Information',
  Enregistrer: 'Spara',
  'Textes en ligne': 'Texter online',
  'Livrer les textes': 'Leverera texterna',
  Indisponible: 'Otillgänglig',
  Disponible: 'Tillgänglig',
  'Les textes ont été livrés avec succès.':
    'Texterna har levererats framgångsrikt.',
  'Le texte a été livré avec succès.': 'Texten har levererats framgångsrikt.',
  'Les textes ont été validés avec succès.':
    'Texterna har validerats framgångsrikt.',
  'Le texte a été validé avec succès.': 'Texten har validerats framgångsrikt.',
  'Voulez-vous vraiment valider les Textes de la commande':
    'Vill du verkligen validera beställningens texter?',
  'Fiche produit longue': 'Lång produktblad',
  'Fiche produit courte': 'Kort produktblad',
  'Un texte est trop long et ne peut pas être exporté sur Excel':
    'En text är för lång och kan inte exporteras till Excel.',
  'Mettre un texte en brouillon en livré':
    'Ändra en text från utkast till levererad',
  'Remettre un texte validé à livré': 'Ändra en validerad text till levererad',
  'Invalider le texte': 'Ogiltigförklara texten',
  'Je souhaiterai modifier mon mot de passe : comment faire ?':
    'Jag skulle vilja ändra mitt lösenord: hur gör jag det?',
  'Comment mettre un contenu en bon pour livraison ?':
    'Hur ställer man in innehåll för leverans?',
  'Je ne peux pas mettre mon contenu en « brouillon »':
    'Jag kan inte spara mitt innehåll som "utkast"',
  'Typix n’a pas enregistré mes dernières modifications':
    'Har Typix inte sparat mina senaste ändringar',
  'Comment rédiger une création de contenu ?':
    'Hur skriver man innehållsskapande?',
  'Comment rédiger une optimisation de contenu ?':
    'Hur skriver man innehållsoptimering?',
  'Je ne vois plus ma commande – que faire ?':
    'Jag ser inte längre min beställning – vad ska jag göra?',
  'Comment générer une facture ?': 'Hur genererar man en faktura?',
  'Je ne peux pas generer ma facture : que faire ?':
    'Jag kan inte generera min faktura: vad ska jag göra?',
  'Tuto process Typix global': 'Global Typix-process - handledning',
  'Tuto mise en ligne des contenus':
    'Hur man laddar upp innehåll - handledning',
  'Modification mot de passe': 'Lösenordsändring',
  'Le test ne me convient pas': 'Testen passar mig inte',
  'Comment valider un contenu dans Typix ?':
    'Hur validerar man innehåll i Typix?',
  'Tuto Facturation': 'Faktureringshandledning',
  'Le statut du contenu a bien été modifié':
    'Innehållets status har ändrats framgångsrikt',
  'Le gap sémantique de ce mot clé n’a pas encore été validé, veuillez contacter le support Typix : support-typix@pixalione.com':
    'Den semantiska luckan för detta nyckelord har ännu inte validerats, vänligen kontakta Typix support: support-typix@pixalione.com',
  'Ce mot clé n’existe pas sur MONETORING, veuillez vérifier avec le support Typix : support-typix@pixalione.com':
    'Detta nyckelord finns inte på MONETORING, kontrollera med Typix support: support-typix@pixalione.com',
  'Récupérer le Gap': 'Hämta gapet',
  'Le statut du contenu a bien été modifié':
    'Innehållets status har ändrats framgångsrikt',
  'Le gap sémantique de ce mot clé n’a pas encore été validé, veuillez contacter le support Typix : support-typix@pixalione.com':
    'Den semantiska luckan för detta nyckelord har ännu inte validerats, vänligen kontakta Typix support: support-typix@pixalione.com',
  'Ce mot clé n’existe pas sur MONETORING, veuillez vérifier avec le support Typix : support-typix@pixalione.com':
    'Detta nyckelord finns inte på MONETORING, kontrollera med Typix support: support-typix@pixalione.com',
  'Récupérer le Gap': 'Hämta gapet',
  'Traduction premium': 'Traducción premium',
  'Rédaction et/ou Traduction intelligente':
    'Intelligent skrivning och/eller översättning',
  'Rédaction et/ou Traduction avec révision':
    'Skrivning och/eller översättning med revision',
  'Rédaction et/ou Traduction premium':
    'Premiumskrivning och/eller översättning',
  'Rédaction et/ou Traduction premium':
    'Premiumskrivning och/eller översättning',
  'Rédaction et/ou Traduction avec révision':
    'Skrivning och/eller översättning med revision',
  'Rédaction et/ou Traduction intelligente':
    'Intelligent skrivning och/eller översättning',
  'Traduction avec révision': 'Översättning med revision',
  'Traduction intelligente': 'Intelligent översättning',
  'Le nombre de mots commandé est supérieur à celui vendu':
    'Antalet beställda ord är större än det sålda',
  'Rédaction et/ou Traduction premium':
    'Premium skrivande och/eller översättning',
  'Rédaction et/ou Traduction smart + review':
    'Smart skrivande och/eller översättning + granskning',
  'Rédaction et/ou Traduction smart': 'Smart skrivande och/eller översättning',
  'Nom Silo': 'Namn Silo',
  'Remettre en brouillon': 'Återställ till utkast',
  'Bon pour Livraison Vérifié': 'Verifierad för leverans',
  'Voulez-vous vraiment remettre en brouillon les Textes de la commande':
    'Är du säker på att du vill återställa beställningens texter till utkast',
  'La vérification du texte a été annulée.': 'Textverifieringen har avbrutits.',
  'Voulez-vous vraiment annuler la vérification du texte?':
    'Vill du verkligen avbryta textverifieringen?',
  'Etes-vous sûr(e) de vouloir regénérer tous les contenus ?':
    'Är du säker på att du vill återskapa allt innehåll?',
  'Je suis sûr(e)': 'Jag är säker',
  'Non, revenir en arrière': 'Nej, gå tillbaka',
};
