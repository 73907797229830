import axiosPrivate from 'config/axiosPrivate';
import { fetchAllOrders } from 'store/Admin/OrderList/features';
import { reDraftTextsOfTextList } from 'store/Admin/OrderDisplay';
import { fetchAdminOrderLineDisplay } from 'store/Admin/OrderDisplay/features';
import { fetchWebsiteOrdersList } from 'store/Admin/WebsiteDisplay/features';
import { filterTextByStatus } from 'utils/Constants';

export const verifyAllTextsDeletionCriteria = (items) => {
  return items.every(
    (item) =>
      item.delivered === false ||
      (item.delivered === true &&
        item.revisionSent === false &&
        item.revisionDone === false &&
        item.validated === false &&
        item.globalRevisionSent === false)
  );
};

export const rePutDeliveredTextsToDraft = async (
  selectedTextsToReDraft,
  setSelectedTextsToReDraft,
  setSelectedTextsForDelivery,
  orderId,
  websiteId,
  setSelectedStatus,
  setConfirmation,
  setIsSuccess,
  setErrorSuccessMsg,
  setErrorSuccess,
  t,
  dispatch,
  setIsDrafted
) => {
  try {
    await axiosPrivate.post('/texts/putListToDraft', selectedTextsToReDraft);

    setConfirmation(false);
    setSelectedTextsToReDraft([]);
    setSelectedTextsForDelivery([]);
    setIsDrafted(false);
    await dispatch(
      reDraftTextsOfTextList({
        selectedTextsToReDraft,
        key: 'delivered',
        value: false,
      })
    );

    await dispatch(
      reDraftTextsOfTextList({
        selectedTextsToReDraft,
        key: 'verifiedByAdmin',
        value: false,
      })
    );

    setSelectedStatus(filterTextByStatus[0]);

    setIsSuccess(true);
    setErrorSuccessMsg(
      t(
        selectedTextsToReDraft.length > 1
          ? 'Les textes ont été remis en brouillon avec succès.'
          : 'Le texte a été remis en brouillon avec succès.'
      )
    );
    setErrorSuccess(true);

    dispatch(fetchAllOrders());
    dispatch(fetchWebsiteOrdersList(websiteId));
    dispatch(fetchAdminOrderLineDisplay(orderId));
  } catch (error) {
    console.error('An error occurred during text validation:', error.message);
  }
};
