/**
 * This function, `checkData`, is used to validate and handle form data for various actions in a React application.
 * It takes in the following parameters:
 *
 * @param {object} data - The form data that needs to be validated using the `TextFormSchema` retrieved from the `<Form>` component of `'@unform/web'.
 * @param {object} formRef - A React reference to the form, used for setting form errors if validation fails.
 * @param {string} buttonName - The name of the button/action triggering the form submission.
 * @param {function} setButtonName - A state-setting function to update the button name in the parent component.
 * @param {function} PutToDraft - A function to be executed when the buttonName is 'PutToDraft'.
 * @param {function} DeliverText - A function to be executed when the buttonName is 'DeliverText'.
 * @param {function} EditText - A function to be executed when the buttonName is 'EditText'.
 * @param {function} ReactivateInitialText - A function to be executed when the buttonName is 'ReactivateInitialText'.
 * @param {function} setConfirmationMsg - A state-setting function to update the confirmation message in the parent component.
 * @param {function} setConfirmation - A state-setting function to show or hide a confirmation dialog in the parent component.
 *
 * The function follows these steps:
 *
 * 1. Clears all previous form errors using `formRef.current.setErrors({})`.
 * 2. Validates the `data` object against the `TextFormSchema` using Yup library.
 * 3. If validation succeeds, it performs an action based on the `buttonName` value using a switch statement
 *
 * 4. If there is a validation error (Yup.ValidationError), the `handleValidationErrors` function is called.
 *    It maps the validation errors to their corresponding fields and sets the errors in the form using `formRef.current.setErrors`.
 *
 * Note: This function is designed to work with a specific form structure and the provided callback functions for each action.
 * It's important to ensure that the `TextFormSchema`, button actions, and state-setting functions are appropriately implemented
 * in the parent component when using this function.
 */

import * as Yup from 'yup';
import i18n from 'i18next';

import { baseTextFormSchema, smartTextFormSchema } from 'utils/schemas';

export async function checkData(
  data,

  formRef,
  action,
  PutToDraft,
  DeliverText,
  KeywordStuffingCall,
  setConfirmationMsg,
  setConfirmation
) {
  try {
    // Remove all previous errors
    formRef.current.setErrors({});

    await baseTextFormSchema.validate(data, {
      abortEarly: false,
    });

    switch (action) {
      case 'PutToDraft':
        PutToDraft();
        break;

      case 'Save':
        setConfirmation(true);
        setConfirmationMsg(
          `${i18n.t('Êtes vous sur de vouloir sauvegarder le texte')}?`
        );
        break;

      case 'KeywordStuffingCall':
        KeywordStuffingCall();
        break;
      case 'DeliverText':
        DeliverText();
        break;

      default:
        break;
    }
  } catch (err) {
    handleValidationErrors(err, formRef);
  }
}

export async function checkDataAdmin(
  data,
  orderType,
  formRef,
  action,
  PutToDraft,
  DeliverText,
  EditText,
  InvalidateText,
  ReactivateInitialText,
  VerifyText
) {
  try {
    // Remove all previous errors
    formRef.current.setErrors({});

    // Function to get the modified schema based on orderType
    const getTextFormSchema = (orderType) => {
      return orderType === 'SMART' ? smartTextFormSchema : baseTextFormSchema;
    };

    const TextFormSchema = getTextFormSchema(orderType);

    await TextFormSchema.validate(data, {
      abortEarly: false,
    });

    switch (action) {
      case 'PutToDraft':
        PutToDraft();
        break;

      case 'DeliverText':
        DeliverText();
        break;

      case 'EditText':
        EditText();
        break;
      case 'InvalidateText':
        InvalidateText();
        break;

      case 'ReactivateInitialText':
        ReactivateInitialText();
        break;

      case 'VerifyText':
        VerifyText();
        break;

      default:
        break;
    }
  } catch (err) {
    handleValidationErrors(err, formRef);
  }
}

export function handleValidationErrors(err, formRef) {
  if (err instanceof Yup.ValidationError) {
    const validationErrors = {};
    err.inner.forEach((error) => {
      validationErrors[error.path] = i18n.t(error.message);
    });
    formRef.current.setErrors(validationErrors);
  }
}
